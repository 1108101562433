import React, { useState, useRef, useEffect, cache } from "react";
import Stack from "@mui/material/Stack";
import {
  AdvancedSearchStyled,
  CloseButton,
  CloseButtonWrapper,
  CustomAutoComplete,
  IconWrapper,
  ItemWithIcon,
  ListingTypes,
  MinMaxTag,
} from "./style";
import { CustomButton } from "../../../common/Buttons/Buttons";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
import { useAreaByCities, useGetCities } from "../../../../hooks/useCities";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import _, { isNull } from "lodash";
import { useRouter } from "next/router";
import useGetSellers from "../../../../hooks/useGetSellers";
import { useProperty } from "../../../PropertyWithFilter/context";
import {
  KeyIcon,
  ShakeHandIcon,
} from "./icons";
import { areaMinMax, priceMinMax } from "../../../../utils/utils";
import useAdvanceSearchForm from "./useAdvanceSeaechForm";
import { Autocomplete, CircularProgress, FormHelperText } from "@mui/material";
import useGetProperties from "../../../PropertyWithFilter/hooks/useGetProperties";
import useSellerSearch from "../../../../hooks/useSellerSearch";
import ClassificationSwiper from '../../../Swiper/ClassificationSwiper';
import { SwiperSlide } from "swiper/react";
import { classificationList } from './classifications';
import { property_types } from './property_types';
import queryString from 'query-string';
import { fetcherApiPost } from "../../../../utils/utils";
import { locale } from "moment";
import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { isMobile } from "react-device-detect";
const AdvancedSearch = ({ visible, setVisible }) => {
  const { t } = useTranslation("common");
  const wrapperRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentSearch, setAgentSearch] = useState("");
  const [selectedListingType, setSelectedListingType] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [propertyClassifications, setPropertyClassifications] = useState(null);
  const [propertyType, setPropertyType] = useState(null);
  const [selectedClassification, setSelectedClassification] = useState([]);
  const [selectedClassificationIds, setSelectedClassificationIds] = useState([]);
  const [minPropertyPrice, setMinPropertyPrice] = useState(null);
  const [maxPropertyPrice, setMaxPropertyPrice] = useState(null);
  const [minPropertyArea, setMinPropertyArea] = useState(null);
  const [maxPropertyArea, setMaxPropertyArea] = useState(null);
  const [minPropertyPriceRange, setMinPropertyPriceRange] = useState(0);
  const [maxPropertyPriceRange, setMaxPropertyPriceRange] = useState(1000);
  const [minPropertyAreaRange, setMinPropertyAreaRange] = useState(0);
  const [maxPropertyAreaRange, setMaxPropertyAreaRange] = useState(100);
  const [propertyPrice, setPropertyPrice] = useState(false);
  const [propertyArea, setPropertyArea] = useState(false);
  const [propertyPriceSlider, setPropertyPriceSlider] = useState([0, 1000]);
  const [propertyAreaSlider, setPropertyAreaSlider] = useState([0, 100]);
  const [placeHolderMinPrice, setplaceHolderMinPrice] = useState(100);
  const [placeHolderMaxPrice, setplaceHolderMaxPrice] = useState(500000);
  const [bedrooms, setBedrooms] = useState(null);
  const [bathrooms, setBathrooms] = useState(null);
  const [furnishing, setFurnishing] = useState(null);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [initialSelected, setInitialSelected] = useState(false);

  const [activeSale, setActiveSale] = useState(false);
  const [activeRent, setActiveRent] = useState(false);

  const { data: areaData, isLoading: isAreaLoading } = useAreaByCities({
    selectedCity: selectedCity?.value,
  });
  useOutsideAlerter(wrapperRef);
  const router = useRouter();
  const { data: cities, isLoading: isCityLoading } = useGetCities({
    showAll: true,
  });
  const { data: sellers, isLoading: isSellerLoading } = useGetSellers();
  const { data: sellerSearch, refetch: sellerSearchList } = useSellerSearch({ filter: { page: 0, pageSize: 10, agentSearch: agentSearch } });
  const cityOptions = _.map(cities?.data, (city) => {
    return {
      value: city?.id,
      label: router?.locale == "en" ? city.nameEn : city.nameAr,
      region: router?.locale == "en" ? city.regionNameEn : city.regionNameAr,
    };
  })

  const {
    handleSubmit,
    errors,
    setValue,
    clearErrors,
  } = useAdvanceSearchForm();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (visible) {
            setVisible(false);
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  // Function to load state data from sessionStorage
  const loadStateFromSessionStorage = () => {
    const serializedState = sessionStorage.getItem('stateData');
    if (serializedState === null) {
      return undefined; // If no data found in sessionStorage, return undefined
    }
    const stateData = JSON.parse(serializedState);
    return stateData;
  };

  // Function to set all states from sessionStorage
  const setStatesFromSessionStorage = async () => {
    const stateData = loadStateFromSessionStorage();
    if (stateData) {
      setSelectedCity(stateData.selectedCity ? _.find(cityOptions, { value: stateData.selectedCity }) : null);
      setSelectedAreaId(stateData.selectedArea ? stateData.selectedArea : null);
      setSelectedAgent(_.find(sellerSearch?.data.results, { id: stateData.selectedAgent }) ? { label: _.find(sellerSearch?.data.results, { id: stateData.selectedAgent })?.name, id: _.find(sellerSearch?.data.results, { id: stateData.selectedAgent })?.id } : null);
      setAgentSearch(stateData.agentSearch);
      setSelectedListingType(stateData.selectedListingType);
      setTotalResults(stateData.totalResults);
      setPropertyClassifications(stateData.propertyClassifications);
      setPropertyType(stateData.propertyType);
      setSelectedClassification(stateData.selectedClassification);
      setSelectedClassificationIds(stateData.selectedClassificationIds);
      setLoading(stateData.loading);
      setMinPropertyPrice(stateData.minPropertyPrice);
      setMaxPropertyPrice(stateData.maxPropertyPrice);
      setMinPropertyArea(stateData.minPropertyArea);
      setMaxPropertyArea(stateData.maxPropertyArea);
      setMinPropertyPriceRange(stateData.minPropertyPriceRange);
      setMaxPropertyPriceRange(stateData.maxPropertyPriceRange);
      setMinPropertyAreaRange(stateData.minPropertyAreaRange);
      setMaxPropertyAreaRange(stateData.maxPropertyAreaRange);
      setPropertyPrice(stateData.propertyPrice);
      setPropertyArea(stateData.propertyArea);
      setPropertyPriceSlider(stateData.propertyPriceSlider);
      setPropertyAreaSlider(stateData.propertyAreaSlider);
      setplaceHolderMinPrice(stateData.placeHolderMinPrice);
      setplaceHolderMaxPrice(stateData.placeHolderMaxPrice);
      setBedrooms(stateData.bedrooms);
      setBathrooms(stateData.bathrooms);
      setFurnishing(stateData.furnishing);
      setPropertyTypeList(stateData.propertyTypeList);
      setInitialSelected(stateData.initialSelected);
    }
  };

  // Call setStatesFromSessionStorage to set states from sessionStorage when component mounts
  useEffect(() => {
    if (visible == true) {
      setStatesFromSessionStorage();
    }
  }, [visible]);

  useEffect(() => {
    //add this becouse get area depend on city
    if (selectedAreaId && areaData) {
      setSelectedArea(_.find(areaData, { id: selectedAreaId }));
    }
  }, [selectedAreaId, areaData]);

  useEffect(() => {
    if (selectedCity && visible === true) {
      setLoading(true)
      const fetchData = async () => {
        try {
          const data = await getAdvanceSearchCount();
          setTotalResults(data?.data?.totalResults)
          const filteredClassificationList = []
          const filteredPropertyTypes = [];
          for (let i = 0; i < classificationList.length; i++) {
            const item = classificationList[i];
            if (data?.data?.propertyClassifications.includes(item.id)) {
              filteredClassificationList.push(item);
            }
          }
          for (let i = 0; i < property_types.length; i++) {
            const item = property_types[i];
            if (data?.data?.propertyTypes.includes(parseInt(item.value))) {
              filteredPropertyTypes.push(item);
            }
          }
          if (selectedCity) {
            setPropertyClassifications(filteredClassificationList)
            setPropertyTypeList(filteredPropertyTypes)
            if (filteredClassificationList?.length <= 0) {
              setSelectedClassificationIds([])
            }
            if (filteredPropertyTypes?.length <= 0) {
              setPropertyType(null);
            }
            if (selectedClassificationIds?.length === 0 || !selectedClassificationIds) {
              _onSelectPropertyType(filteredClassificationList[0]);
              setInitialSelected(true)
            }
          }
          if (maxPropertyPrice === null || minPropertyPrice === null) {
            setMinPropertyPriceRange(data?.data?.minPrice == data?.data?.maxPrice ? 0 : data?.data?.minPrice)
            setMaxPropertyPriceRange(data?.data?.maxPrice)
            setPropertyPriceSlider([data?.data?.minPrice == data?.data?.maxPrice ? 0 : data?.data?.minPrice, data?.data?.maxPrice])
          }
          setplaceHolderMaxPrice(data?.data?.maxPrice)
          setplaceHolderMinPrice(data?.data?.minPrice == data?.data?.maxPrice ? 0 : data?.data?.minPrice)
          setLoading(false)
        } catch (error) {
          console.log('error advance search', error);
        } finally {
          setLoading(false);
        }

      };
      fetchData();
    }

  }, [selectedCity, selectedAgent, selectedArea, selectedListingType, bedrooms, bathrooms, furnishing, maxPropertyPrice, minPropertyPrice, maxPropertyArea, minPropertyArea, selectedClassification, propertyType, totalResults]);


  const getAdvanceSearchCount = async () => {
    let sellerIds = []
    if (selectedAgent) {
      sellerIds.push(selectedAgent.id)
    }
    let districtIds = []
    if (selectedArea) {
      districtIds.push(selectedArea.id)
    }
    let furnishType = null;
    if (furnishing == 0) {
      furnishType = 20
    } else if (furnishing == 1) {
      furnishType = 10
    }
    let params = {
      northWestCoordinate: {
        Latitude: 0,
        Longitude: 0,
      },
      southEastCoordinate: {
        Latitude: 0,
        Longitude: 0,
      },
      districtIds: districtIds,
      cityId: selectedCity ? selectedCity?.value : undefined,
      listingType: selectedListingType,
      propertyClassifications: selectedClassificationIds ? selectedClassificationIds : null,
      propertyType: propertyType?.value !== "null" && propertyType?.value && parseInt(propertyType?.value) ? parseInt(propertyType?.value) : undefined,
      minPrice: minPropertyPrice !== "undefined" || minPropertyPrice ? parseInt(minPropertyPrice) : null,
      maxPrice: maxPropertyPrice !== "undefined" || maxPropertyPrice ? parseInt(maxPropertyPrice) : null,
      minArea: minPropertyArea !== "undefined" || minPropertyArea ? parseInt(minPropertyArea) : null,
      maxArea: maxPropertyArea !== "undefined" || maxPropertyArea ? parseInt(maxPropertyArea) : null,
      numberBedrooms: parseInt(bedrooms),
      numberBathrooms: parseInt(bathrooms),
      isOccupiedBefore: null,
      furnishType: furnishType,
      page: 0,
      pageSize: 0,
      sellerIds
    };
    // console.log('params' , JSON.stringify(params));
    const { data } = await fetcherApiPost(`/search/v1/Listings/advancecount`, params);
    return data
  }


  const onSearchAgents = (value) => {
    if (value == "") {
      setAgentSearch("");
      sellerSearchList();
    } else {
      setAgentSearch(value);
      setTimeout(() => {
        sellerSearchList();
      }, 100)
    }
  }

  function _handleListingType(type) {
    if (type === 'sale') {
      setActiveSale(true)
    } else if (type === 'rent') {
      setActiveRent(true)
    } else {
      setActiveSale(false)
      setActiveRent(false)

    }
    setTimeout(() => {
      setSelectedListingType(type);
    }, 100)
    setPropertyPriceSlider([0, 1000]);
    // setPropertyAreaSlider([0, 100]);
    setMinPropertyPrice(null);
    setMaxPropertyPrice(null);
    setMinPropertyArea(null);
    setMaxPropertyArea(null);
  }


  const _onSelectPropertyType = (item) => {
    if (!item || !item.value) {
      console.error("Invalid item:", item);
      return; // Exit the function early if item is invalid or doesn't have a value
    }

    let updatedSelection;
    let selectionIds;
    if (!initialSelected && !selectedClassification?.length) {
      updatedSelection = [item.value];
      selectionIds = [item.id];
      setInitialSelected(true);
    } else {
      if (selectedClassification?.includes(item.value)) {
        updatedSelection = selectedClassification?.filter((selectedItem) => selectedItem !== item.value);
        selectionIds = selectedClassificationIds?.filter((selectedItem) => selectedItem !== item.id);
      } else {
        const idExists = selectedClassificationIds?.includes(item.id);
        if (idExists) {
          selectionIds = selectedClassificationIds;
          updatedSelection = selectedClassification;
        } else {
          selectionIds = [...(selectedClassificationIds || []), item.id];
          updatedSelection = [...(selectedClassification || []), item.value];
        }
      }
    }
    setSelectedClassificationIds(selectionIds)
    setSelectedClassification(updatedSelection);
  }

  function renderItemPropertyClassifications() {
    return propertyClassifications?.map((item, k) => {
      const isSelected = selectedClassificationIds?.filter(selectedItem => selectedItem === item.id).length > 0;
      return (
        <SwiperSlide key={k} onClick={() => {
          clearWhenChangeCityOrAreaOrAgent('NotDelete')
          _onSelectPropertyType(item);
        }}
          style={{ margin: "10px 0px" }}>
          <Chip
            style={{ cursor: "pointer" }}
            avatar={<Avatar src={item.icon} />}
            label={t(item.title)}
            variant={isSelected ? "filled" : "outlined"} // Use "filled" when isSelected is true
          />
        </SwiperSlide>
      );
    });
  }

  function renderItemSubCategory() {
    return (
      <ClassificationSwiper>
        {propertyTypeList.map((item, k) => {
          return (
            <SwiperSlide key={k} onClick={() => {
              setPropertyType(item);
              setMinPropertyPrice(null);
              setMaxPropertyPrice(null);
              setMinPropertyArea(null);
              setMaxPropertyArea(null);
              setMinPropertyPriceRange(0);
              setMaxPropertyPriceRange(1000);
              setPropertyPrice(false);
              setPropertyArea(false);
              setPropertyPriceSlider([0, 1000]);
              setBedrooms(null);
              setBathrooms(null);
              setFurnishing(null);
              const areaMinAndMax = _.find(areaMinMax, {
                value: item?.value,
              });
              setPropertyAreaSlider([areaMinAndMax?.min ? areaMinAndMax?.min : 0, areaMinAndMax?.max ? areaMinAndMax?.max : 100]);
              setMinPropertyAreaRange(areaMinAndMax?.min ? areaMinAndMax?.min : 0)
              setMaxPropertyAreaRange(areaMinAndMax?.max ? areaMinAndMax?.max : 100)
            }}>
              <Chip
                style={{ cursor: "pointer" }}
                avatar={<Avatar src={item.icon} />}
                label={t(item.title)}
                variant={propertyType?.value === item.value ? "" : "outlined"}
              />
            </SwiperSlide>
          )
        })}
      </ClassificationSwiper>
    );
  }

  const handleChangePriceSlider = (event, newValue, activeThumb) => {
    setPropertyPrice(true);
    setMinPropertyPrice(newValue[0]);
    setMaxPropertyPrice(newValue[1]);
    setPropertyPriceSlider(newValue);
  };

  const handleChangeAreaSlider = (event, newValue, activeThumb) => {
    setPropertyArea(true);
    setMinPropertyArea(newValue[0]);
    setMaxPropertyArea(newValue[1]);
    setPropertyAreaSlider(newValue);
  };

  function renderRoomItems(type) {
    return (
      <Box sx={{ my: "20px" }}>
        <ListingTypes narrow>
          {_.times(6, (index) => (
            <ItemWithIcon
              narrow
              onClick={() => {
                type == 'bathrooms' ? setBathrooms(index) : setBedrooms(index);
              }}
              key={`bathrooms-${index}`}
              active={type == 'bathrooms' ? bathrooms == index : bedrooms == index}
            >
              {index == 0 && <p>{t("any")}</p>}
              {index > 0 && index < 5 && <p>{index}</p>}
              {index == 5 && <p>{"5 +"}</p>}
            </ItemWithIcon>
          ))}
        </ListingTypes>
      </Box>
    )
  }


  const convertArabicToEnglish = (value) => {
    let result = '';
    if (value) {
      for (let i = 0; i < value?.length; i++) {
        const charCode = value.charCodeAt(i);
        if (charCode >= 0x0660 && charCode <= 0x0669) {
          result += String.fromCharCode(charCode - 0x0660 + 0x0030);
        } else if (charCode >= 0x06F0 && charCode <= 0x06F9) {
          result += String.fromCharCode(charCode - 0x06F0 + 0x0030);
        } else {
          result += value[i];
        }
      }
    }
    return result;
  };


  const onSubmit = (data) => {
    setVisible(false)
    const parsed = {}
    if (bedrooms) {
      bedrooms ? parsed.bedrooms = bedrooms : '';
    }
    if (bathrooms) {
      bathrooms ? parsed.bathroom = bathrooms : '';
    }

    let furnishType = null;
    if (furnishing == 0) {
      furnishType = t("un_furnished")?.toLocaleLowerCase()
    } else if (furnishing == 1) {
      furnishType = t("furnished")?.toLocaleLowerCase()
    }
    if (furnishType) {
      parsed.furnishing = furnishType;
    }

    if (propertyPrice) {
      minPropertyPrice ? parsed.min_price = parseInt(minPropertyPrice) : '';
      maxPropertyPrice ? parsed.max_price = parseInt(maxPropertyPrice) : '';
    }
    if (propertyArea) {
      minPropertyArea ? parsed.min_area = parseInt(minPropertyArea) : '';
      maxPropertyArea ? parsed.max_area = parseInt(maxPropertyArea) : '';
    }
    if (selectedAgent) {
      parsed.sellerIds = selectedAgent.id;
    }
    let newurl;

    let propertyTypeName = t(propertyType?.title)
    let cityRegion = selectedCity?.region
    let cityName = selectedCity?.label
    let districtName = router.locale == "en" ? selectedArea?.nameEn : selectedArea?.nameAr
    let listingTypeName = t(selectedListingType)

    if (!selectedListingType) {
      newurl = `/${router.locale == "ar" ? "المنطقة-" + cityRegion?.toLocaleLowerCase() : cityRegion?.toLocaleLowerCase() + "-region"}/${cityName && cityName.trim() !== "" && cityName !== undefined ? "/" + cityName?.toLocaleLowerCase() : ""}${(districtName !== undefined && districtName && districtName.trim() !== "") ? '/' + districtName?.toLocaleLowerCase() : ""}`;
    } else {
      newurl = `-${router.locale == "en" ? t("for").toLocaleLowerCase() + "-" : ""}${listingTypeName?.toLocaleLowerCase()}/${router.locale == "ar" ? "المنطقة-" + cityRegion?.toLocaleLowerCase() : cityRegion?.toLocaleLowerCase() + "-region"}/${cityName !== "" && cityName.trim() && cityName !== undefined ? "/" + cityName?.toLocaleLowerCase() : ""}${(districtName !== undefined && districtName && districtName.trim() !== "") ? '/' + districtName?.toLocaleLowerCase() : ""}`;
    }
    let url = newurl.replace(" ", "-")
    const qParams = queryString.stringify(parsed);
    if (router.locale === "ar") {
      window.location.href = `/ksa/${propertyTypeName ? propertyTypeName?.toLocaleLowerCase() : t("properties")}${url}/${qParams != "" ? '?' + qParams : ""}`;
    } else if (router.locale === "en") {
      window.location.href = `/${router.locale}/ksa/${propertyTypeName ? propertyTypeName?.toLocaleLowerCase() : t("properties")}${url}/${qParams != "" ? '?' + qParams : ""}`;
    }
    //saved search data on session
    saveDataInSession()

  };

  const saveDataInSession = () => {
    const stateData = {
      selectedCity: selectedCity?.value,
      selectedArea: selectedArea?.id,
      selectedAgent: selectedAgent?.id,
      agentSearch,
      selectedListingType,
      totalResults,
      propertyClassifications,
      propertyType,
      selectedClassification,
      selectedClassificationIds,
      loading,
      minPropertyPrice,
      maxPropertyPrice,
      minPropertyArea,
      maxPropertyArea,
      minPropertyPriceRange,
      maxPropertyPriceRange,
      minPropertyAreaRange,
      maxPropertyAreaRange,
      propertyPrice,
      propertyArea,
      propertyPriceSlider,
      propertyAreaSlider,
      placeHolderMinPrice,
      placeHolderMaxPrice,
      bedrooms,
      bathrooms,
      furnishing,
      propertyTypeList,
      initialSelected
    };
    const serializedState = JSON.stringify(stateData);
    sessionStorage.setItem('stateData', serializedState)
  }

  const handleClearForm = () => {
    setSelectedCity(null);
    setSelectedArea(null);
    setSelectedAgent(null);
    setAgentSearch("");
    setSelectedListingType(null);
    setTotalResults(0);
    setPropertyClassifications(null);
    setPropertyType(null);
    setSelectedClassification([]);
    setSelectedClassificationIds([]);
    setLoading(false);
    setMinPropertyPrice(null);
    setMaxPropertyPrice(null);
    setMinPropertyArea(null);
    setMaxPropertyArea(null);
    setMinPropertyPriceRange(0);
    setMaxPropertyPriceRange(1000);
    setMinPropertyAreaRange(0);
    setMaxPropertyAreaRange(100);
    setPropertyPrice(false);
    setPropertyArea(false);
    setPropertyPriceSlider([0, 1000]);
    setPropertyAreaSlider([0, 100]);
    setplaceHolderMinPrice(100);
    setplaceHolderMaxPrice(500000);
    setBedrooms(null);
    setBathrooms(null);
    setFurnishing(null);
    setPropertyTypeList([]);
    setInitialSelected(false);
    sessionStorage.clear();
  };


  const clearWhenChangeCityOrAreaOrAgent = (flag) => {
    setMinPropertyPrice(null);
    setMaxPropertyPrice(null);
    setMinPropertyArea(null);
    setMaxPropertyArea(null);
    setMinPropertyPriceRange(0);
    setMaxPropertyPriceRange(1000);
    setMinPropertyAreaRange(0);
    setMaxPropertyAreaRange(100);
    setPropertyPrice(false);
    setPropertyArea(false);
    setPropertyPriceSlider([0, 1000]);
    setPropertyAreaSlider([0, 100]);
    setplaceHolderMinPrice(100);
    setplaceHolderMaxPrice(500000);
    setBedrooms(null);
    setBathrooms(null);
    setFurnishing(null);
    if (flag == 'delete') {
      setSelectedClassificationIds([])
    }
    setPropertyType(null);
  }
  return (
    <AdvancedSearchStyled visible={visible}>
      {/* close Button */}
      <CloseButtonWrapper>
        <IconButton onClick={() => setVisible(false)}>
          <CloseButton />
        </IconButton>
      </CloseButtonWrapper>

      {/* clear Button */}
      <Stack direction="row" spacing={3} sx={{ ml: "-12px" }}>
        <CustomButton
          variant="text"
          onClick={() => {
            handleClearForm();
          }}
        >
          {t("clear")}
        </CustomButton>
        <Typography variant="body1" sx={{ pt: "8px" }}>
          {t("advance_search")}
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ my: "20px" }}>
          {/*------- city -------*/}
          <InputLabel sx={{ fontSize: "14px", mb: "9px", color: "text.main" }}>
            {t("city")}
          </InputLabel>
          <CustomAutoComplete
            key="city_id"
            autoSelect={true}
            autoHighlight={true}
            options={cityOptions}
            value={selectedCity}
            sx={{ width: "100%", mb: "17px", }}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={isCityLoading ? t("loading") : t("select_city")}
                error={errors?.city_id?.message}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.value} name="city_id" style={{ backgroundColor: option?.label === selectedCity?.label ? "#00BEAD" : 'white', color: option?.label === selectedCity?.label ? "white" : 'black' }}>
                {option.label}
              </li>
            )}
            onChange={(event, value) => {
              setSelectedArea(null);
              setSelectedAreaId(null);
              setSelectedCity(value);
              setValue("city_id", value);
              clearErrors("city_id");
              clearWhenChangeCityOrAreaOrAgent('delete')
            }}
            noOptionsText={t("no_options")}
          />

          {/*--------- handel error city ---------*/}
          {errors?.city_id && (
            <FormHelperText sx={{ color: "red.main", mt: "-15px", mb: "10px" }}>
              {errors?.city_id?.message}
            </FormHelperText>
          )}

          {/*----------------- District -----------------*/}
          {selectedCity && areaData?.length > 0 &&
            <><InputLabel sx={{ fontSize: "14px", mb: "9px", color: "text.main" }}>
              {t("area")}
            </InputLabel>
              <CustomAutoComplete
                autoHighlight={true}
                key="area"
                name="area_id"
                options={areaData || []}
                getOptionLabel={(option) => {
                  return router?.locale == "en" ? option.nameEn : option.nameAr;
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.value} style={{ backgroundColor: option?.id === selectedArea?.id ? "#00BEAD" : 'white', color: option?.id === selectedArea?.id ? "white" : 'black' }}>
                    {router.locale == "en" ? option?.nameEn : option?.nameAr}
                  </li>
                )}
                value={selectedArea || null}
                sx={{ width: "100%", mb: "17px" }}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={isAreaLoading ? t("loading") : t("select_District")}
                  />
                )}
                onChange={(event, value) => {
                  setSelectedArea(value);
                  clearWhenChangeCityOrAreaOrAgent('delete')
                }}
                noOptionsText={t("no_options")}
              />
            </>}

          {/*------------------- agents --------------------*/}
          <InputLabel sx={{ fontSize: "14px", mb: "9px", color: "text.main" }}>
            {t("agents")}
          </InputLabel>
          <Autocomplete
            autoHighlight={true}
            key="seller"
            name="seller_id"
            id="combo-box-seller"
            value={selectedAgent}
            options={sellerSearch?.data.results.length > 0 ? sellerSearch?.data.results.map((option) => {
              return {
                label: option.name,
                id: option?.id,
              };
            }) : []}
            renderOption={(props, option) => (
              <li {...props} key={option.value} style={{ backgroundColor: option?.id === selectedAgent?.id ? "#00BEAD" : 'white', color: option?.id === selectedAgent?.id ? "white" : 'black' }}>
                {option?.label}
              </li>
            )}
            sx={{ width: "100%", mb: "17px" }}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    onSearchAgents(e.target.value)
                  }
                }}
                placeholder={isSellerLoading ? t("loading") : t("select_Agent")}
              />
            )}
            onChange={(event, value) => {
              setSelectedAgent(value)
              clearWhenChangeCityOrAreaOrAgent('delete')
            }}
            noOptionsText={t("no_options")}
          />
        </Box>


        {/*----------- listing_type -----------*/}
        <Box sx={{ my: "20px" }}>
          <Typography variant="body1" sx={{ mb: "15px" }}>
            {t("listing_type")}
          </Typography>
          <ListingTypes centered>
            <ItemWithIcon
              onClick={() => _handleListingType(isMobile ? 'sale' : (selectedListingType === 'sale' ? null : 'sale'))}
              onTouchStart={(e) => {
                e.preventDefault(); 
                _handleListingType(isMobile ? 'sale' : (selectedListingType === 'sale' ? null : 'sale'))
              }}
              active={selectedListingType === 'sale'}
            >
              <IconWrapper width={23} height={30}>
                <ShakeHandIcon />
              </IconWrapper>
              <Typography variant="body1">{t("sale")}</Typography>
            </ItemWithIcon>
            <ItemWithIcon
              onClick={() => {
                _handleListingType(isMobile ? 'rent' : (selectedListingType === 'rent' ? null : 'rent'))
              }}
              onTouchStart={(e) => {
                e.preventDefault(); 
                _handleListingType(isMobile ? 'rent' : (selectedListingType === 'rent' ? null : 'rent'))
              }}
              active={selectedListingType === 'rent'}
            >
              <IconWrapper width={21} height={21}>
                <KeyIcon />
              </IconWrapper>
              <Typography variant="body1">{t("rent")}</Typography>
            </ItemWithIcon>
          </ListingTypes>

          {(selectedListingType == null && totalResults !== 0) && (
            <FormHelperText sx={{ color: "red.main" }}>
              {t("select_a_type")}
            </FormHelperText>
          )}
        </Box>


        {/* ------------------Classification and property type------------------ */}

        {propertyClassifications?.length > 0 && selectedCity !== null ?
          <Box sx={{ my: "20px" }}>
            <Typography variant="body1" sx={{ mb: "15px" }}>
              {t("property_type")}
            </Typography>
            <ClassificationSwiper>
              {renderItemPropertyClassifications()}
            </ClassificationSwiper>

            {propertyTypeList.length > 0 && (
              <div style={{ marginTop: 10 }}>
                {renderItemSubCategory()}
              </div>
            )}
          </Box>
          : <></>
        }

        {(propertyType?.value == "10" ||
          propertyType?.value == "30" ||
          propertyType?.value == "40" ||
          propertyType?.value == "20") && (
            <>
              <Box sx={{ my: "20px" }}>
                {/* ------------  price ------------- */}
                <Typography variant="body1" sx={{ mb: "15px" }}>
                  {t("property_price")}
                </Typography>
                <Stack direction={"row"} spacing={2} sx={{ mb: "10px" }}>
                  {!propertyPrice ? (
                    <MinMaxTag
                      onClick={() => {
                        setPropertyPrice(true);
                      }}
                    >
                      {placeHolderMinPrice}
                    </MinMaxTag>
                  ) : (
                    <TextField
                      id="min"
                      placeholder={t("min_price")}
                      type={"text"}
                      value={minPropertyPrice}
                      InputLabelProps={{
                        shrink: minPropertyPrice ? true : false,
                      }}
                      variant="outlined"
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^[\u0660-\u0669\u06F0-\u06F90-9]+$/;

                        if (regex.test(value) || value === '') {
                          value = convertArabicToEnglish(value);
                          if (value === '' || isNaN(parseInt(value))) {
                            setMinPropertyPrice('');
                          } else {
                            if (parseInt(value) < 0) {
                              value = '0';
                            } else if (value.length > 7) {
                              value = value.slice(0, 7);
                            }
                            setMinPropertyPrice(value);
                          }
                        }

                        // var value = parseInt(e.target.value, 10);
                        // const numberAsString = value.toString();
                        // if (numberAsString.length <= 7) {
                        //   if (value < 0) {
                        //     value = 0;
                        //   }
                        //   setMinPropertyPrice(value);
                        // }
                      }}
                      size="small"
                    />
                  )}

                  {!propertyPrice ? (
                    <MinMaxTag
                      onClick={() => {
                        setPropertyPrice(true);
                      }}
                    >
                      {placeHolderMaxPrice}
                    </MinMaxTag>
                  ) : (
                    <TextField
                      id="max"
                      placeholder={t("max_price")}
                      type={"text"}
                      value={maxPropertyPrice}
                      InputLabelProps={{
                        shrink: maxPropertyPrice ? true : false,
                      }}
                      variant="outlined"
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^[\u0660-\u0669\u06F0-\u06F90-9]+$/;
                        if (regex.test(value) || value === '') {
                          value = convertArabicToEnglish(value);
                          if (value === '' || isNaN(parseInt(value))) {
                            setMaxPropertyPrice(value);
                            ('');
                          } else {
                            if (parseInt(value) < 0) {
                              value = '0';
                            } else if (value.length > 7) {
                              value = value.slice(0, 7);
                            }
                            setMaxPropertyPrice(value);
                          }
                        }

                        // var value = parseInt(e.target.value, 10);
                        // const numberAsString = value.toString();
                        // if (numberAsString.length <= 7) {
                        //   if (value < 0) {
                        //     value = 0;
                        //   }
                        //   setMaxPropertyPrice(value);
                        // }
                      }}
                      size="small"
                    />
                  )}
                </Stack>

                <Box sx={{ padding: "0 10px 20px 10px" }}>
                  <Slider
                    value={propertyPriceSlider}
                    onChange={handleChangePriceSlider}
                    min={minPropertyPriceRange}
                    max={maxPropertyPriceRange}
                  />
                </Box>
              </Box>

              <Box sx={{ my: "20px" }}>
                <Typography variant="body1" sx={{ mb: "15px" }}>
                  {t("property_area")}
                </Typography>
                <Stack direction={"row"} spacing={2} sx={{ mb: "10px" }}>
                  {!propertyArea ?
                    <MinMaxTag
                      onClick={() => {
                        setPropertyArea(true);
                      }}
                    >
                      {t("no_min_area")}
                    </MinMaxTag>
                    :
                    <TextField
                      id="min"
                      placeholder={t("min_area")}
                      type={"text"}
                      value={minPropertyArea}
                      InputLabelProps={{
                        shrink: minPropertyArea ? true : false,
                      }}
                      variant="outlined"
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^[\u0660-\u0669\u06F0-\u06F90-9]+$/;
                        if (regex.test(value) || value === '') {
                          value = convertArabicToEnglish(value);
                          if (value === '' || isNaN(parseInt(value))) {
                            setMinPropertyArea('');
                          } else {
                            if (parseInt(value) < 0) {
                              value = '0';
                            } else if (value.length > 4) {
                              value = value.slice(0, 4);
                            }
                            setMinPropertyArea(value);
                          }
                        }
                        // var value = parseInt(e.target.value, 10);
                        // const numberAsString = value.toString();
                        // if (numberAsString.length <= 4) {
                        //   if (value < 0) {
                        //     value = 0;
                        //   }
                        //   setMinPropertyArea(value);
                        // }

                      }}
                      size="small"

                    />
                  }

                  {!propertyArea ?
                    <MinMaxTag onClick={() => { setPropertyArea(true) }}>
                      {t("no_max_area")}
                    </MinMaxTag>
                    :
                    <TextField
                      id="max"
                      placeholder={t("max_area")}
                      type={"text"}
                      value={maxPropertyArea}
                      InputLabelProps={{
                        shrink: maxPropertyArea ? true : false,
                      }}
                      variant="outlined"
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^[\u0660-\u0669\u06F0-\u06F90-9]+$/;
                        if (regex.test(value) || value === '') {
                          value = convertArabicToEnglish(value);
                          if (value === '' || isNaN(parseInt(value))) {
                            setMaxPropertyArea('');
                          } else {
                            if (parseInt(value) < 0) {
                              value = '0';
                            } else if (value.length > 4) {
                              value = value.slice(0, 4);
                            }
                            setMaxPropertyArea(value);
                          }
                        }
                        // var value = parseInt(e.target.value, 10);
                        // const numberAsString = value.toString();
                        // if (numberAsString.length <= 4) {
                        //   if (value < 0) {
                        //     value = 0;
                        //   }
                        //   setMaxPropertyArea(value);
                        // }
                      }}
                      size="small"
                    />
                  }
                </Stack>
                <Box sx={{ padding: "0 10px 20px 10px" }}>
                  <Slider
                    value={propertyAreaSlider}
                    onChange={handleChangeAreaSlider}
                    min={minPropertyAreaRange}
                    max={maxPropertyAreaRange}
                  />
                </Box>
              </Box>
            </>
          )}

        {(propertyType?.value == "10" || propertyType?.value == "30") && (
          <>
            {/*  ------ bedrooms & bathrooms ------*/}
            <Typography variant="body1" sx={{ mb: "15px" }}>
              {t("bedrooms")}
            </Typography>
            {renderRoomItems('bedrooms')}
            <Typography variant="body1" sx={{ mb: "15px" }}>
              {t("bathrooms")}
            </Typography>
            {renderRoomItems('bathrooms')}
            <Box sx={{ my: "20px" }}>
              <Typography variant="body1" sx={{ mb: "15px" }}>
                {t("furnishing")}
              </Typography>
              <ListingTypes narrow>
                {_.times(2, (index) => (
                  <ItemWithIcon
                    narrow
                    onClick={() => {
                      setFurnishing(index);
                    }}
                    key={`furnishing-${index}`}
                    active={furnishing == index}
                  >
                    {index == 0 && <p>{t("un_furnished")}</p>}
                    {index == 1 && <p>{t("furnished")}</p>}
                  </ItemWithIcon>
                ))}
              </ListingTypes>
            </Box>
          </>
        )}

        <Box sx={{ textAlign: "center" }}>
          <CustomButton
            variant="contained"
            wider="true"
            type="submit"
            disabled={!selectedCity || totalResults === 0}
          >
            {!loading ? !selectedCity ? t("select_search_options") : totalResults !== 0 ? t('showing', { totalResults }) : t("No_result") : <CircularProgress size={20} color="white" />}
          </CustomButton>
        </Box>
      </form>

    </AdvancedSearchStyled >
  );
};

export default AdvancedSearch;