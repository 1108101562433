import React, { useState, useEffect } from "react";
import Image from "next/image";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import {
  ContainerStyled,
  Content,
  FooterWrapper,
  GradientArtTop,
  MainStyled,
} from "../MainLayout/style";
import {
  CloseButtonWrapper,
  IconWrapper,
  LeftBlock,
  MenuFoldIcon,
  MenuIconWrapper,
  MenuItem,
  MenuItems,
  MenuUnfoldIcon,
  ProfileHeaderBlock,
  RightBlock,
  SidebarLayoutStyled,
  SidebarMenuBlock,
  UserInfoBlockBottom,
  UserInfoImageWrapper,
} from "./style";
import DefaultProfilePic from "../../../public/images/default-profile-pic.jpg";
import Typography from "@mui/material/Typography";
import * as _ from "lodash";
import { useTranslation } from "next-i18next";
import {
  RequestIcon,
  HeartIcon,
  EyeIcon,
  QuestionIcon,
  LogoutIcon,
  ProfileIcon,
  VerifiedIcon,
  ChatIcon,
  NotificationIcon,
} from "./icons";
import { useAuth } from "../../context/auth";
import Link from "next/link";
import { useRouter } from "next/router";
import { useGetFollowing } from "../../components/AgentDetails/hooks/useGetFollow";
import { useGetProfile } from "../../components/ProfileForm/hooks/useGetProfile";
import { VillaIcon, EmployeeIcon } from "../../components/AdvancedSearchHeader/components/AdvancedSearch/icons";
import AdvancedSearch from "../../components/AdvancedSearchHeader/components/AdvancedSearch/AdvancedSearch";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { isIOS } from 'react-device-detect';
import Cookies from "js-cookie";
import { parseJwt } from "../../utils/jwt";

const SidebarLayout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [canAddListing, setCanAddListing] = useState(false);

  const { t } = useTranslation("common");
  const { user, logout } = useAuth();
  const router = useRouter();
  let is_Profile_Complete = Cookies.get("IsProfileComplete");

  const limit = 100;
  const offset = 0;
  const userId = user?.id;

  const { data: profile, isLoading: profileLoading } = useGetProfile();
  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if(Cookies.get("access_token") != null) {
      const jwt = parseJwt(Cookies.get("access_token"));
      setCanAddListing(jwt.canAddListing == "True" ? true : false);
    }
  }, [canAddListing]);

  const handleClick = () => {
    setMenuOpen(false);
  };

  const sidebarMenu = [
    {
      title: t("profile"),
      icon: <ProfileIcon />,
      active: router?.pathname == "/profile" ? true : false,
      key: "profile",
      path: "/profile",
      width: 17,
      height: 17,
      userType: true,
      onClick: handleClick,

    },

    {
      title: t("listing_management"),
      icon: <VillaIcon />,
      active: router?.pathname == "/listing-management" ? true : false,
      key: "listing",
      path: "/listing-management",
      width: 21,
      // userType: profile?.userType != 1000 ? true : false,
      userType: canAddListing ? true : false,
      height: 21,
      onClick: handleClick,
    },

    {
      title: t("employee-management"),
      icon: <EmployeeIcon />,
      active: router?.pathname == "/employee" ? true : false,
      key: "employee",
      path: "/employee",
      width: 21,
      // userType: profile?.userType != 1000 ? true : false,
      userType: profile?.agentType == 1? true : false,
      height: 21,
      onClick: handleClick,

    },

    {
      title: t("my_requests"),
      icon: <RequestIcon />,
      active: router?.pathname == "/my-request" ? true : false,
      key: "request",
      path: "/my-request",
      width: 17,
      height: 12,
      userType: true,
      onClick: handleClick,

    },
    {
      title: t("messages"),
      icon: <ChatIcon />,
      active: router?.pathname == "/messages" ? true : false,
      key: "message",
      path: "/messages",
      width: 16,
      height: 16,
      userType: true,
      onClick: handleClick,

    },
    // {
    //   title: t("my_preferences"),
    //   icon: <RequestIcon />,
    //   active: false,
    //   key: "settings",
    //   path: "/",
    // },

    {
      title: t("likes"),
      icon: <HeartIcon />,
      active: router?.pathname == "/like" ? true : false,
      key: "fav",
      path: "/like",
      width: 17,
      height: 19,
      userType: true,
      onClick: handleClick,

    },
    {
      title: t("upgrade_your_account"),
      icon: <VerifiedIcon />,
      active: false,
      key: "upgrade",
      path: "/",
      width: 17,
      height: 17,
      active: router?.pathname == "/sign-up/owner-type" ? true : false,
      path: "/sign-up/owner-type",
      userType: profile?.userType == 1000 ? true : false,
      onClick: handleClick,

    },
    {
      title: t("notifications"),
      icon: <NotificationIcon />,
      active: router?.pathname == "/notifications" ? true : false,
      key: "verified",
      path: "/notifications",
      width: 20,
      height: 20,
      userType: true,
      onClick: handleClick,

    },
    {
      title: t("recently-viewed"),
      icon: <EyeIcon />,
      active: router?.pathname == "/recently-view" ? true : false,
      key: "eye",
      path: "/recently-view",
      width: 12,
      height: 18,
      userType: true,
      onClick: handleClick,

    },
    {
      title: t("contact_us"),
      icon: <QuestionIcon />,
      active: router?.pathname == "/contact-us" ? true : false,
      key: "questions",
      path: "/contact-us",
      width: 17,
      height: 12,
      userType: true,
      onClick: handleClick,

    },
  ];

  return (
    <ContainerStyled maxWidth="xl">
      <Header menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
      <AdvancedSearch visible={menuVisible} setVisible={setMenuVisible} />
      <MainStyled solidBg>
        <GradientArtTop />
        <Content>
          <SidebarLayoutStyled>
            <RightBlock menuOpen={menuOpen}>
              <ProfileHeaderBlock>
                <CloseButtonWrapper>
                  <IconButton
                    sx={{ p: "3px" }}
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                </CloseButtonWrapper>
                <UserInfoImageWrapper>
                  <Image
                    src={
                      user?.avatarSourceUrl
                        ? user?.avatarSourceUrl
                        : DefaultProfilePic
                    }
                    alt="User image"
                    fill
                    style={{ objectFit: "cover", objectPosition: "center" }}
                  />
                </UserInfoImageWrapper>
                <Typography variant="body1" size="smaller" sx={{ mb: "5px" }}>
                   {/* {router?.locale == "en" ? user?.firstName : user?.firstNameAr ? user?.firstNameAr : user?.firstName} {router?.locale == "en" ? user?.lastName : user?.lastNameAr ? user?.lastNameAr : user?.lastName} */}
                  {
    router?.locale === "en" 
      ? user?.firstName 
      : (user?.firstNameAr && user?.firstNameAr.trim() !== "" ? user?.firstNameAr : user?.firstName)
  }{" "} 
  {
    router?.locale === "en" 
      ? user?.lastName 
      : (user?.lastNameAr && user?.lastNameAr.trim() !== "" ? user?.lastNameAr : user?.lastName)
  }
                </Typography>
                <div style={{ direction: "ltr" }}>
                  <Typography
                    variant="body1En"
                    size="smaller"
                    sx={{ mb: "5px" }}
                  >
                    @{user?.userName}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body1En"
                    size="smaller"
                    sx={{ mb: "5px" }}
                  >
                    {t(`${user?.userTypeText}`)}
                    {/* {user?.userTypeText} */}
                  </Typography>
                </div>
                <UserInfoBlockBottom>
                  <Link href="/following">
                    <Typography
                      variant="body1"
                      size="smaller"
                      sx={{ color: "primary.main" }}
                    >
                      {t("following")}
                    </Typography>
                  </Link>
                  <Link href="/following">
                    <Typography
                      variant="body1"
                      size="big"
                      sx={{ color: "primary.main" }}
                    >
                      {profile?.numberFollowings}
                    </Typography>
                  </Link>
                </UserInfoBlockBottom>
              </ProfileHeaderBlock>

              <SidebarMenuBlock>
                <MenuItems>
                  {_.map(sidebarMenu, (item, index) => (
                    <Link href={item.path} key={item.key} onClick={item.onClick} >
                      {item?.userType && (
                        <MenuItem active={item.active}>
                          <IconWrapper
                            width={item.width}
                            height={item.height}
                            flip={item.key === "questions"}
                            logout={item.key === "logout"}
                            active={item.active}
                          >
                            {item.icon}
                          </IconWrapper>
                          <Typography
                            key={`menu-item-${index}`}
                            variant="body1"
                            size="smaller"
                            sx={{ color: "primary.main" }}
                          >
                            {t(item.title)}
                          </Typography>
                        </MenuItem>
                      )}
                    </Link>
                  ))}
                  <MenuItem logout={true} onClick={() => handleLogout()}>
                    <IconWrapper width={14} height={16} logout={true}>
                      <LogoutIcon />
                    </IconWrapper>
                    <Typography
                      variant="body1"
                      size="smaller"
                      sx={{ color: "brownGray.main" }}
                    >
                      {t("sign_out")}
                    </Typography>
                  </MenuItem>
                </MenuItems>
              </SidebarMenuBlock>
            </RightBlock>
            <LeftBlock>
              <MenuIconWrapper onClick={() => setMenuOpen(!menuOpen)}>
                <Typography
                  variant="body1"
                  sx={{ color: "primary.main", mr: "5px" }}
                >
                  {t("menu")}
                </Typography>
                {menuOpen ? (
                  <MenuFoldIcon style={{ color: "#00bead" }} />
                ) : (
                  <MenuUnfoldIcon style={{ color: "#00bead" }} />
                )}
              </MenuIconWrapper>
              {children}
            </LeftBlock>
          </SidebarLayoutStyled>
        </Content>
      </MainStyled>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </ContainerStyled>
  );
};

export default SidebarLayout;
