import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const HeaderStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.typography.pxToRem(20),
  marginBottom: theme.typography.pxToRem(20),
  justifyContent: "space-between",
  backgroundColor: "#fff",
  [theme.breakpoints.up("md")]: {
    marginTop: theme.typography.pxToRem(30),
    marginBottom: theme.typography.pxToRem(30),
  },
}));

const HeaderLeft = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const MenuButton = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "visible",
})(({ theme, visible }) => ({
  display: "block",
  width: "35px",
  height: "24px",
  position: "relative",
  transform: "rotate(0deg)",
  transition: ".5s ease-in-out",
  marginRight: "15px",
  cursor: "pointer",
  "& span": {
    display: "block",
    position: "absolute",
    height: "4px",
    width: "100%",
    background: theme.palette.primary.main,
    borderRadius: "9px",
    opacity: 1,
    left: 0,
    transform: `rotate(0deg)`,
    transition: `.25s ease-in-out`,
  },
  "& span:nth-child(1)": {
    top: visible ? "11px" : "0px",
    transform: visible ? "rotate(135deg)" : "rotate(0)",
  },

  "& span:nth-child(2)": {
    top: "10px",
    left: visible ? "-40px" : 0,
    opacity: visible ? 0 : 1,
  },

  "& span:nth-child(3)": {
    top: visible ? "11px" : "20px",
    transform: visible ? "rotate(-135deg)" : "rotate(0)",
  },
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

const HeaderCenter = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme, visible }) => ({
  position: "fixed",
  top: 0,
  right: 0,
  zIndex: 9,
  minHeight: "100vh",
  backgroundColor: theme.palette.white.main,
  boxShadow: `0px 4px 10px 0px rgba(0, 0, 0, 0.2)`,
  justifyContent: "space-between",
  padding: `${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(15)}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  transition: "all 0.3s ease",
  opacity: visible ? 1 : 0,
  transform: visible ? "translateX(0)" : "translateX(100%)",
  minWidth: "200px",
  [theme.breakpoints.up("md")]: {
    zIndex: 1,
    minHeight: "auto",
    justifyContent: "space-between",
    flexDirection: "row",
    boxShadow: "none",
    bottom: 0,
    flex: 1,
    position: "relative",
    transform: "translateX(0)",
    opacity: 1,
    padding: `0 ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(
      20
    )}`,
  },
  [theme.breakpoints.up("lg")]: {
    padding: `0 ${theme.typography.pxToRem(60)} 0 ${theme.typography.pxToRem(
      40
    )}`,
  },
}));

const HeaderRight = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const CenterNavBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  marginBottom: theme.typography.pxToRem(15),
  [theme.breakpoints.up("md")]: {
    marginBottom: 0,
  },
}));

const ButtonsWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  margin: `0 ${theme.typography.pxToRem(-10)}`,
  [theme.breakpoints.up("lg")]: {
    margin: `0 ${theme.typography.pxToRem(-20)}`,
  },
}));

const HeaderLink = styled("a", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "active",
})(({ theme, active }) => ({
  // fontSize: `${toRem(16)}`,
  fontSize: theme.typography.pxToRem(16),
  color: active ? theme.palette.primary.main : theme.palette.brownGray.main,
  marginRight: `${theme.typography.pxToRem(20)}`,
  cursor: "pointer",
  transition: "color 0.2s ease",
  [theme.breakpoints.up("lg")]: {
    marginRight: `${theme.typography.pxToRem(50)}`,
  },
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const HeaderLinkOutlined = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  cursor: "pointer",
  transition: "color 0.2s ease",
  border: `1px solid ${theme.palette.primary.main}`,
  padding: "8px",
  borderRadius: "15px",
  backgroundColor: "#fff",
  transition: "background 0.3s ease",
  "& p": {
    color: theme.palette.primary.main,
  },
  "&:hover p": {
    color: "#fff",
  },
  "&:hover": {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));

const HeaderStyledNew = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: `${theme.typography.pxToRem(14)}`,
  position: "sticky",
  top: 0,
  zIndex: 999,
  background: "#fff",
  margin: "0 -15px",
  [theme.breakpoints.up("sm")]: {
    margin: "0 -25px",
  },
}));

const HeaderStyledNewMain = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1000,



  background: "#fff",
}));

const HeaderLeftNew = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isSearchExpand",
})(({ theme, isSearchExpand }) => ({
  alignItems: "center",
  display: isSearchExpand ? "none" : "flex",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderCenterNew = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const HeaderRightNew = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "visible",
})(({ theme, visible }) => ({
  position: "absolute",
  backgroundColor: "#fff",
  padding: "15px",
  top: "65px",
  zIndex: 99,
  borderRadius: "5px",
  boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
  display: visible ? "flex" : "none",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("lg")]: {
    position: "relative",
    display: "block",
    top: 0,
    padding: 0,
    boxShadow: "none",
    zIndex: 1,
  },
}));

const UserActionsWrapperNew = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isSearchExpand",
})(({ theme, isSearchExpand }) => ({
  padding: `0 0 0 ${theme.typography.pxToRem(15)}`,
  display: isSearchExpand ? "none" : "block",
  [theme.breakpoints.up("lg")]: {
    display: "block",
    padding: `0 ${theme.typography.pxToRem(24)}`,
  },
}));

const PropertyActionsWrapperNew = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  flexShrink: 0,
  display: "none",
  [theme.breakpoints.up("lg")]: {
    display: "block",
  },
}));

const PropertyActionLink = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "5px",
  padding: "8px",
  backgroundColor: "#fff",
  color: theme.palette.primary.main,
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.up("sm")]: {
    padding: "8px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "8px",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "10px",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "10px 16px",
  },
  "&:hover p": {
    color: "#fff",
  },
  "& p": {
    fontSize: "13px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  },
}));

export {
  HeaderStyled,
  HeaderLink,
  HeaderLeft,
  HeaderRight,
  HeaderCenter,
  CenterNavBlock,
  ButtonsWrapper,
  MenuButton,
  HeaderLinkOutlined,
  HeaderStyledNew,
  HeaderLeftNew,
  HeaderRightNew,
  HeaderCenterNew,
  UserActionsWrapperNew,
  PropertyActionsWrapperNew,
  PropertyActionLink,
  HeaderStyledNewMain
};
