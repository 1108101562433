import Image from "next/image";
import React from "react";
import {
  AuthenticationLayoutStyled,
  Contents,
  Header as AuthHeader,
  Logo,
} from "./style";
import CompanyLogo from "../../../public/images/header-logo.png";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
import {
  ContainerStyled,
  FooterWrapper,
  MainStyled,
} from "../MainLayout/style";
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";
import { useState } from "react";

const AuthenticationLayout = ({ children, title }) => {
  const { t } = useTranslation("common");
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <ContainerStyled maxWidth="xl">
      <Header menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
      <MainStyled>
        <AuthenticationLayoutStyled>
          <Contents>
            <AuthHeader>
              <Logo>
                <Image
                  src={CompanyLogo}
                  alt="Company logo"
                  fill
                  style={{ objectFit: "contain" }}
                />
              </Logo>
              <Typography variant="body1">{t(title)}</Typography>
            </AuthHeader>
            {children}
          </Contents>
        </AuthenticationLayoutStyled>
      </MainStyled>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </ContainerStyled>
  );
};

export default AuthenticationLayout;
