import axios from "axios";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { API_PATH, publicUrls } from "../utils/utils";

export function useAxiosSearch() {
  const { locale } = useRouter();

  const axiosInstance = axios.create({
    baseURL: API_PATH,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": locale == "en" ? "en-US" : "ar-SA en-US",
      "x-api-key": process.env.NEXT_PUBLIC_SECURITY_KEY,
      // "Cache-Control": "max-age=0"
    },
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = Cookies.get("access_token");
      const city_id = Cookies.get("city_id");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      // if (city_id && typeof city_id != "undefined") {
      //   config.headers["city"] = city_id;
      // }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // axiosInstance.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     if (
  //       error.response.status === 401 &&
  //       publicUrls[window.location.pathname]?.length < 1
  //     ) {
  //       window.location.href = "/login";
  //     }
  //   }
  // );

  // axiosInstance.interceptors.response.use(
  //   (response) => response,
  //   async (error) => {
  //     let config = error?.config;

  //     if (error.response.status === 401 && !config._retry) {
  //       // if (isTokenExpired) {
  //       //   await logoutUser();
  //       //   return Promise.reject(error);
  //       // }

  //       config._retry = true;
  //       // try {
  //       //   const newAccessToken = await refresh();

  //       //   if (newAccessToken) {
  //       //     config.headers.Authorization = `Bearer ${newAccessToken}`;
  //       //   }

  //       //   return axiosInstance(config);
  //       // } catch (_error) {
  //       //   if (_error.response && _error.response.data) {
  //       //     return Promise.reject(_error.response.data);
  //       //   }
  //       //   return Promise.reject(_error);
  //       // }
  //     }
  //     if (error.response.status === 403 && error.response.data) {
  //       return Promise.reject(err.response.data);
  //     }

  //     return Promise.reject(error);
  //   }
  // );

  return axiosInstance;
}
