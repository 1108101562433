import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { FreeMode } from "swiper";
import { useRouter } from "next/router";
import { SwiperStyled } from "./style";

const ClassificationSwiper = ({ children, onReachEnd }) => {
  const router = useRouter();

  return (
    <SwiperStyled>
      <Swiper
        // slidesPerView={"auto"}
        spaceBetween={40}
        centeredSlides={false}
        freeMode={true}
        slidesPerView={"auto"}
        modules={[FreeMode]}
        className="mySwiper"
        dir={router.locale == "ar" ? "rtl" : "ltr"}
        onReachEnd={onReachEnd}
        // breakpoints={{
        //   400: {
        //     slidesPerView: 3,
        //   },
        //   600: {
        //     slidesPerView: 4,
        //   },
        //   800: {
        //     slidesPerView: 5,
        //   },
        //   900: {
        //     slidesPerView: 6,
        //   },
        //   1000: {
        //     slidesPerView: 8,
        //   },
        //   1200: {
        //     slidesPerView: 12,
        //   },
        //   1536: {
        //     slidesPerView: 13,
        //   },
        // }}
      >
        {children}
      </Swiper>
    </SwiperStyled>
  );
};

export default ClassificationSwiper;
