import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

import { MdLanguage } from "react-icons/md";
import toRem from "../../../../../utils/toRem";

const LanguageSwitcherStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const LanguageIcon = styled(MdLanguage, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: toRem(18),
  marginLeft: toRem(5),
  color: `${theme.palette.primary.main}`,
}));

export { LanguageSwitcherStyled, LanguageIcon };

// const template = styled("div", {
//   shouldForwardProp: (prop) => isPropValid(prop),
// })(({ theme }) => ({}));

// [theme.breakpoints.up("sm")]: {},
// [theme.breakpoints.up("md")]: {},
// [theme.breakpoints.up("lg")]: {},
// [theme.breakpoints.up("xl")]: {},
