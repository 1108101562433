import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase, ref, remove, onValue } from "firebase/database";
import Cookies from "js-cookie";
import {
  FCM_API_KEY,
  FCM_AUTH_DOMAIN,
  FCM_PROJECT_ID,
  FCM_STORAGE_BUCKET,
  FCM_MESSAGING_SENDER_ID,
  FCM_APP_ID,
  FCM_VAPIDKEY,
} from "./utils";
import { isAndroid, isBrowser } from "react-device-detect";

const firebaseConfig = {
  apiKey: FCM_API_KEY,
  authDomain: FCM_AUTH_DOMAIN,
  projectId: FCM_PROJECT_ID,
  storageBucket: FCM_STORAGE_BUCKET,
  messagingSenderId: FCM_MESSAGING_SENDER_ID,
  appId: FCM_APP_ID
};

let realtimedb = null;
let messaging = null;
if (typeof window != "undefined") {
  const firebaseApp = initializeApp(firebaseConfig);
  realtimedb = getDatabase(firebaseApp);
  if(isBrowser || isAndroid) {
    messaging = getMessaging(firebaseApp);
  }
}

export const fetchToken = async () => {
  try {
    const tokenInLocalForage = Cookies.get("sanadak_fcm_token");
z
    // Return the token if it is alredy in our local storage
    if (tokenInLocalForage) {
      return tokenInLocalForage;
    }
    const status = await Notification.requestPermission();
    if (status && status === "granted") {
      // Get new token from Firebase
      const fcm_token = await getToken(messaging, {
        vapidKey: FCM_VAPIDKEY,
      });

      // Set token in our local storage
      if (fcm_token) {
        Cookies.set("sanadak_fcm_token", fcm_token, {
          expires: 86400, //1 day
        });
        return fcm_token;
      }
    } else {
      return "not-grant";
    }
  } catch (error) {
    return null;
  }
};

export const onMessageListener = () => {
  // if (typeof window == "undefined") {
  //   return false;
  // }
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};

export {
  realtimedb,
  ref,
  onValue,
  remove
}
