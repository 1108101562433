import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import Button from "@mui/material/Button";

const CustomButton = styled(Button)(({ theme, pill, wider, fluid }) => ({
  borderRadius: pill ? theme.shape.pillRadius : theme.shape.borderRadius,
   padding: wider
    ? `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(30)}`
    : theme.typography.pxToRem(8),
  width: fluid ? "100%" : "auto",
  lineHeight: 1,
  textTransform: "none",
  boxShadow: "none",
  fontSize: theme.typography.pxToRem(16),
  "&:hover": {
    boxShadow: "none",
  },
}));

const FollowingButton = styled(Button)(({ theme, pill, wider, fluid }) => ({
  borderRadius: pill ? theme.shape.pillRadius : theme.shape.borderRadius,
   padding: wider
    ? `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(30)}`
    : theme.typography.pxToRem(8),
    width: fluid ? "100%" : "40%",
  lineHeight: 1,
  textTransform: "none",
  boxShadow: "none",
  fontSize: theme.typography.pxToRem(16),
  "&:hover": {
    boxShadow: "none",
  },
}));

const SwitchSlug = styled(Button)(({ theme, wider, fluid }) => ({
  // padding: wider
  //   ? `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(30)}`
  //   : theme.typography.pxToRem(8),
  // width: fluid ? "100%" : "auto",
  lineHeight: 1,
  textTransform: "none",
  boxShadow: "none",
  fontSize: theme.typography.pxToRem(16),
  "&:hover": {
    backgroundColor: '#00BEAD',
  },


}));

const GradientButton = styled(CustomButton, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 1%, ${theme.palette.secondary.main})`,
  color: theme.palette.white.main,
  transition: "background 0.3s ease",
  "&:hover": {
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark} 1%, ${theme.palette.secondary.dark})`,
  },
  "&:disabled": {
    backgroundImage: `linear-gradient(to right, ${theme.palette.grey.main} 1%, ${theme.palette.grey.light})`,
    color: theme.palette.grey.dark,
    cursor: "not-allowed",
    // You can add more styles for the disabled state as needed
  },
}));

const WhiteButton = styled(CustomButton, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: theme.palette.red.main,
  backgroundColor: "#fff",
  boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
  "&:hover": {
    boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
  },
}));

const WhiteButtonBlk = styled(CustomButton, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: "#000",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
  "&:hover": {
    boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
  },
}));

const RedButton = styled(CustomButton, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: "#fff !important",
  backgroundColor: "#e23636",
  boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
  "&:hover": {
    boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
    backgroundColor: "#d32020",
  },
}));

const ColouredButton = styled(CustomButton, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "color",
})(({ theme, color }) => ({
  color: color == "#fff" ? "#344054 !important" : "#fff !important",
  backgroundColor: color,
  border: color == "#fff" ? `1px solid #D0D5DD` : `1px solid ${color}`,
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: color,
    opacity: 0.8,
  },
}));

export { CustomButton, SwitchSlug,GradientButton, WhiteButton, RedButton, ColouredButton, WhiteButtonBlk,FollowingButton };
