// residential
import apartment from '../../../../../public/icons/residential/u_Apartment.svg';
import building from '../../../../../public/icons/residential/u_building.svg';
import complex from '../../../../../public/icons/residential/u_complex.svg';
import villa from '../../../../../public/icons/residential/u_Villa.svg';
import land from '../../../../../public/icons/residential/u_desert.svg';
import rest_house from '../../../../../public/icons/residential/u_Rest house.svg';
import floor from '../../../../../public/icons/residential/u_floor.svg';
import parking_lot from '../../../../../public/icons/residential/u_parking-square.svg';
import room from '../../../../../public/icons/residential/u_room.svg';
import studio from '../../../../../public/icons/residential/u_Studio.svg';

import office from '../../../../../public/icons/commercial/u_Office.svg';
import cinema from '../../../../../public/icons/commercial/u_cinema.svg';
import gallery from '../../../../../public/icons/commercial/u_gallery.svg';
import hospital from '../../../../../public/icons/commercial/u_hospital.svg';
import school from '../../../../../public/icons/commercial/u_school.svg';
import warehouse from '../../../../../public/icons/commercial/u_warehouse.svg';
import workshop from '../../../../../public/icons/commercial/u_workshop.svg';
import tower from '../../../../../public/icons/commercial/u_tower.svg';
import store from '../../../../../public/icons/commercial/u_store.svg';
import hotel from '../../../../../public/icons/commercial/u_hotel.svg';
import atm from '../../../../../public/icons/commercial/u_ATM.svg';
import pump from '../../../../../public/icons/commercial/u_pump.svg';

import farm from '../../../../../public/icons/agriculture/u_farmland.svg';
import factory from '../../../../../public/icons/industrial/u_Factory.svg';

export const property_types = [
    {
        title: "apartment",
        icon: apartment,
        value: "10",
        classification: ["residential"]
    },
    {
        title: "villa",
        icon: villa,
        value: "30",
        classification: ["residential"]
    },
    {
        title: "land",
        icon: land,
        value: "40",
        classification: ["residential", "commercial", "industrial", "medical", "agriculture", "education"]
    },
    {
        title: "building",
        icon: building,
        value: "20",
        classification: ["residential", "commercial"]
    },
    {
        title: "rest_house",
        icon: rest_house,
        value: "60",
        classification: ["residential", "commercial", "agriculture"]
    },
    {
        title: "Office",
        icon: office,
        value: "70",
        classification: ["commercial"]
    },
    {
        title: "store",
        icon: store,
        value: "80",
        classification: ["commercial"]
    },
    {
        title: "floor",
        icon: floor,
        value: "85",
        classification: ["residential"]
    },
    {
        title: "Studio",
        icon: studio,
        value: "90",
        classification: ["residential"]
    },
    {
        title: "room",
        icon: room,
        value: "95",
        classification: ["residential"]
    },
    {
        title: "compound",
        icon: complex,
        value: "100",
        classification: ["residential", "commercial"]
    },
    {
        title: "tower",
        icon: tower,
        value: "105",
        classification: ["commercial"]
    },
    {
        title: "Gallery",
        icon: gallery,
        value: "110",
        classification: ["commercial"]
    },
    {
        title: "warehouse",
        icon: warehouse,
        value: "115",
        classification: ["commercial", "industrial"]
    },
    {
        title: "kiosk",
        icon: store,
        value: "120",
        classification: ["commercial"]
    },
    {
        title: "cinema",
        icon: cinema,
        value: "125",
        classification: ["commercial"]
    },
    {
        title: "hotel",
        icon: hotel,
        value: "130",
        classification: ["commercial"]
    },
    {
        title: "parking_lot",
        icon: parking_lot,
        value: "135",
        classification: ["residential", "commercial", "industrial"]
    },
    {
        title: "workshop",
        icon: workshop,
        value: "140",
        classification: ["commercial", "industrial"]
    },
    {
        title: "atm",
        icon: atm,
        value: "145",
        classification: ["commercial"]
    },
    {
        title: "factory",
        icon: factory,
        value: "150",
        classification: ["industrial"]
    },
    {
        title: "school",
        icon: school,
        value: "155",
        classification: ["commercial", "education"]
    },
    {
        title: "hospital",
        icon: hospital,
        value: "160",
        classification: ["commercial", "medical"]
    },
    // {
    //     title: "electric_power_station",
    //     icon: "",
    //     value: "ElectricPowerStation",
    //     classification: ["commercial"]
    // },
    {
        title: "telecom_tower",
        icon: tower,
        value: "170",
        classification: ["commercial", "industrial"]
    },
    {
        title: "gas_station",
        icon: pump,
        value: "175",
        classification: ["commercial"]
    },
    {
        title: "farm",
        icon: farm,
        value: "180",
        classification: ["agriculture"]
    }
];