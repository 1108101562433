import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const CustomSearchWithButtonStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: `0 ${theme.typography.pxToRem(10)}`,
  border: `1px solid #f1f1f1`,
  borderRadius: "8px",
  height: "32px",
  maxWidth: "250px",
  "& .MuiOutlinedInput-root": {
    height: "32px",
    padding: "0px  !important",
    "& fieldset": {
      padding: "0px !important",
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px !important",
    },
  },
}));

export { CustomSearchWithButtonStyled };
