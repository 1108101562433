import Image from "next/image";
import React from "react";
import { BrandIcon, BrandStyled } from "./style";
import Logo from "../../../../../../public/images/header-logo.svg";
import LogoMini from "../../../../../../public/images/mini-logo.svg";
import { HeaderLink } from "../../style";
import { rgbDataURL } from "../../../../../utils/utils";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../../../../utils/theme";
import { useProperty } from "../../../../PropertyWithFilter/context";

const Brand = () => {
  const { t } = useTranslation("common");
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { handleReset } = useProperty();

  return (
    <BrandStyled>
      <Link href="/">
        <BrandIcon
          onClick={() => {
            handleReset();
          }}
        >
          <Image
            priority
            src={matches ? Logo : LogoMini}
            alt={t("sanadak_logo")}
            fill
            style={{ objectFit: "contain" }}
            blurDataURL={rgbDataURL(212, 241, 244)}
          />
        </BrandIcon>
      </Link>
    </BrandStyled>
  );
};

export default Brand;
