import React from "react";
import IconButton from "@mui/material/IconButton";
import Image from "next/image";

const Messages = () => {
  return (
    <IconButton
      sx={{ padding: 0, "&:hover": { backgroundColor: "transparent" } }}
    >
      <Image
        src={require("./icons/messages-icon.svg")}
        alt="Messages icon"
        width={25}
        height={25}
      />
    </IconButton>
  );
};

export default Messages;
