import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const PropertyTypeSwitcherStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  margin: `${theme.typography.pxToRem(15)} 0`,
  [theme.breakpoints.up("xl")]: {
    margin: 0,
  },
}));

export { PropertyTypeSwitcherStyled };
