import { forwardRef } from "react";
import NextLink from "next/link";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import Link from "@mui/material/Link";

const CustomLink = forwardRef(function CustomLink(
  props,
  ref,
  href = "#",
  as,
  prefetch = false
) {
  return (
    <NextLink
      href={href}
      as={as}
      prefetch={prefetch}
      passHref
      legacyBehavior
      {...props}
    >
      <Link ref={ref} {...props} />
    </NextLink>
  );
});

export const StyledLink = styled(CustomLink, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  textDecoration: "none",
  ":before": {
    content: '""',
    position: "absolute",
    display: "block",
    width: "100%",
    height: "2px",
    bottom: -3,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    transform: "scaleX(0)",
    transformOrigin: "top left",
    transition: "transform 0.3s ease",
  },
  ":hover::before": {
    transform: "scaleX(1)",
  },
}));

export default CustomLink;
