import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const AuthenticationLayoutStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#EAEBEE",
  minHeight: "100vh",
  marginBottom: "-75px",
  paddingBottom: "60px",
}));

const Contents = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  backgroundColor: "#fff",
  padding: `${theme.typography.pxToRem(55)} ${theme.typography.pxToRem(15)}`,
  borderRadius: "15px",
  width: "100%",
  maxWidth: "580px",
  margin: "15px",
}));

const Header = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "30px",
}));

const Logo = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  width: "214px",
  height: "80px",
  marginBottom: "60px",
}));

export { AuthenticationLayoutStyled, Contents, Header, Logo };
