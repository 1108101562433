import React, { useState, useEffect } from "react";
import AdvancedSearchHeader from "../../components/AdvancedSearchHeader";
import AdvancedSearch from "../../components/AdvancedSearchHeader/components/AdvancedSearch";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import {
  ContainerStyled,
  Content,
  FooterWrapper,
  GradientArtTop,
  MainStyled,
} from "./style";
import { useProperty } from "../../components/PropertyWithFilter/context";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const MainLayout = ({ children }) => {
  const { t } = useTranslation("common");
  const [menuVisible, setMenuVisible] = useState(false);
  const { mapFullScreen } = useProperty();
  const router = useRouter(); 
  useEffect(() => {
    if (menuVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [menuVisible]);

  return (
    <ContainerStyled maxWidth="xl">
      {mapFullScreen && (router?.pathname == "/" || router?.pathname == "/property/[slug]"  || router?.pathname == "/project-model/[slug]" ||router?.pathname?.includes('ksa') ) ?
         null : (
        <Header menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
      )}
      <AdvancedSearch visible={menuVisible} setVisible={setMenuVisible} />
      {/* <AdvancedSearchHeader
        menuVisible={menuVisible}
        setMenuVisible={setMenuVisible}
      /> */}

      {/* <div
        style={{
          position: "relative",
          // overflow: "hidden",
        }}
      >
        <AdvancedSearch visible={menuVisible} setVisible={setMenuVisible} />
      </div> */}
      <MainStyled>
        <GradientArtTop />
        <Content>{children}</Content>
      </MainStyled>
      {mapFullScreen &&
        (router?.pathname == "/" ||
          router?.pathname == "/property/[slug]") ? null : (
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      )}
    </ContainerStyled>
  );
};

export default MainLayout;
