import React, { useEffect, useState } from "react";
import InputBase from "@mui/material/InputBase";
import { CustomSearchWithButtonStyled } from "./style";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "next-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as _ from "lodash";
import useGetAllSearchResult from "../../../hooks/useGetAllSearchResult";
import { useRouter } from "next/router";
import { useSearchDebounce } from "../../../utils/utils";

const CustomSearchWithButton = () => {
  const [selected, setSelected] = useState(null);
  // const [inputValue, setInputValue] = useState("");
  const [inputValue, setInputValue] = useSearchDebounce();

  const [searchResultList, setSearchResultList] = useState(null);
  const router = useRouter();
  const [enabled, setEnabled] = useState(false);
  const { t } = useTranslation("common");
  const page = 100;

  const filters = {
    pageSize: page,
    query: inputValue,
  };

  const { data, isLoading, refetch } = useGetAllSearchResult({
    filters,
    enabled: enabled,
    setSearchResultList,
  });

  useEffect(() => {
    if (inputValue) {
      refetch();
    } else {
      setSearchResultList(null);
    }
  }, [inputValue]);

  const searchResult = _.filter(searchResultList?.results, (option) => {
    if (
      option.entityType == "profile" ||
      option.entityType == "project" ||
      option.entityType == "listing" ||
      option.entityType == "place"
    ) {
      return option;
    }
  });
  return (
    <CustomSearchWithButtonStyled>
      <Autocomplete
        id="search"
        options={searchResult}
        filterOptions={(x) => x}
        groupBy={(option) => option?.entityType}
        getOptionLabel={(option) => {
          if (option.entityType == "profile") {
            return option.fullName;
          } else if (option.entityType == "listing") {
            return (
              option["descriptor"]?.title || option["descriptor"]?.addressText
            );
          } else if (option.entityType == "place") {
            return option["descriptor"].title;
          } else if (option.entityType == "project") {
            return option.projectName;
          }
        }}
        sx={{ width: 300 }}
        value={selected}
        onChange={(event, newValue, reason) => {
          if (reason == "selectOption") {
            let navigateUrl = false;
            if (newValue?.entityType == "project") {
              navigateUrl = `/project/${newValue?.id}`;
            } else if (newValue?.entityType == "listing") {
              navigateUrl = `/property/${newValue?.id}`;
            } else if (newValue?.entityType == "profile") {
              navigateUrl = `/agent/${newValue?.sellerUsername}`;
            } else if (newValue?.entityType == "place") {
              navigateUrl =router.locale =="ar" ?  `/areas/${newValue?.id}` : `/en/areas/${newValue?.id}`;
            }
            if (navigateUrl) {
              router.push({ pathname: navigateUrl });
            }
          } else {
            setSelected(newValue);
          }
        }}
        loading={isLoading}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={t("search")} />
        )}
        renderOption={(props, option) => {
          let label = false;
          if (option.entityType == "profile") {
            label = option.fullName;
          } else if (option.entityType == "listing") {
            label =
              option["descriptor"]?.title || option["descriptor"]?.addressText;
          } else if (option.entityType == "place") {
            label = option["descriptor"].title;
          } else if (option.entityType == "project") {
            label = option.projectName;
          }
          if (label) {
            return (
              <li {...props} key={option.id}>
                {label}
              </li>
            );
          }
        }}
      />
      <SearchIcon />
    </CustomSearchWithButtonStyled>
  );
};

export default CustomSearchWithButton;
