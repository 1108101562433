import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const AdvancedSearchHeaderStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "20px",
  backgroundColor: "#fff",
  [theme.breakpoints.up("xl")]: {
    flexDirection: "row",
    paddingBottom: 0,
  },
}));

const Divider = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.lightGray.main}`,
  position: "absolute",
  bottom: 0,
  left: "-14px",
  right: "-14px",
  [theme.breakpoints.up("sm")]: {
    left: "-24px",
    right: "-24px",
  },
}));

export { AdvancedSearchHeaderStyled, Divider };
