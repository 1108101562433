import SvgIcon from "@mui/material/SvgIcon";

const ProfileIcon = () => (
  <SvgIcon inheritViewBox>
    <path
      data-name="Icon awesome-user-alt"
      d="M8.134 9.151a4.575 4.575 0 1 0-4.575-4.576 4.576 4.576 0 0 0 4.575 4.576zm4.066 1.016h-1.75a5.531 5.531 0 0 1-4.632 0H4.067A4.067 4.067 0 0 0 0 14.234v.508a1.525 1.525 0 0 0 1.525 1.525h13.217a1.525 1.525 0 0 0 1.525-1.525v-.508a4.067 4.067 0 0 0-4.067-4.067z"
    />
  </SvgIcon>
);

const RequestIcon = () => (
  <SvgIcon inheritViewBox>
    <path
      data-name="Icon awesome-file-alt"
      d="M7.161 4.348V0H.767A.765.765 0 0 0 0 .767V15.6a.765.765 0 0 0 .767.767h10.741a.765.765 0 0 0 .767-.767V5.115H7.928a.769.769 0 0 1-.767-.767zm2.046 7.544a.385.385 0 0 1-.384.384H3.452a.385.385 0 0 1-.384-.384v-.256a.385.385 0 0 1 .384-.384h5.37a.385.385 0 0 1 .384.384zm0-2.046a.385.385 0 0 1-.384.384H3.452a.385.385 0 0 1-.384-.384V9.59a.385.385 0 0 1 .384-.384h5.37a.385.385 0 0 1 .384.384zm0-2.3V7.8a.385.385 0 0 1-.384.384H3.452a.385.385 0 0 1-.383-.384v-.256a.385.385 0 0 1 .384-.384h5.37a.385.385 0 0 1 .384.384zM12.275 3.9v.195H8.184V0h.195a.767.767 0 0 1 .543.224l3.13 3.133a.765.765 0 0 1 .223.543z"
    />
  </SvgIcon>
);

const HeartIcon = () => (
  <SvgIcon inheritViewBox>
    <path
      data-name="Icon feather-heart"
      d="M19.858 5.962a5 5 0 0 0-7.072 0l-.964.964-.964-.964a5 5 0 1 0-7.072 7.072l.965.966 7.072 7.072L18.895 14l.964-.964a5 5 0 0 0 0-7.072z"
      transform="translate(-2.323 -4.497)"
    />
  </SvgIcon>
);

const VerifiedIcon = () => (
  <SvgIcon inheritViewBox>
    <path
      data-name="Icon awesome-check-circle"
      d="M16.775 8.669A8.106 8.106 0 1 1 8.669.562a8.106 8.106 0 0 1 8.106 8.107zm-9.044 4.292 6.014-6.014a.523.523 0 0 0 0-.74l-.74-.74a.523.523 0 0 0-.74 0l-4.9 4.9-2.29-2.29a.523.523 0 0 0-.74 0l-.74.74a.523.523 0 0 0 0 .74l3.4 3.4a.523.523 0 0 0 .736.004z"
      transform="translate(-.563 -.563)"
    />
  </SvgIcon>
);

const EyeIcon = () => (
  <SvgIcon inheritViewBox>
    <path
      data-name="Icon awesome-eye"
      d="M17.769 10.006A9.955 9.955 0 0 0 8.938 4.5a9.956 9.956 0 0 0-8.83 5.506 1 1 0 0 0 0 .906 9.955 9.955 0 0 0 8.83 5.506 9.956 9.956 0 0 0 8.83-5.506 1 1 0 0 0 .001-.906zm-8.83 4.922a4.469 4.469 0 1 1 4.469-4.469 4.469 4.469 0 0 1-4.47 4.469zm0-7.449a2.958 2.958 0 0 0-.786.118 1.485 1.485 0 0 1-2.076 2.076 2.973 2.973 0 1 0 2.861-2.194z"
      transform="translate(0 -4.5)"
    />
  </SvgIcon>
);

const ChatIcon = () => (
  <SvgIcon inheritViewBox>
    <g data-name="Icon ionic-ios-chatbubbles">
      <path
        data-name="Path 751"
        d="M19.525 13.2a.874.874 0 0 1 .119-.44 1.214 1.214 0 0 1 .076-.112 5.843 5.843 0 0 0 1-3.256 6.134 6.134 0 0 0-6.263-6.022 6.22 6.22 0 0 0-6.146 4.797A5.8 5.8 0 0 0 8.177 9.4a6.117 6.117 0 0 0 6.166 6.1 7.426 7.426 0 0 0 1.7-.278c.407-.112.811-.26.916-.3a.953.953 0 0 1 .335-.061.937.937 0 0 1 .364.072l2.044.725a.488.488 0 0 0 .141.036.287.287 0 0 0 .288-.288.463.463 0 0 0-.018-.1z"
        transform="translate(-4.965 -2.625)"
      />
      <path
        data-name="Path 752"
        d="M13.316 20.043a3.45 3.45 0 0 1-.885.107 7.015 7.015 0 0 1-6.666-6.612c0-.238-.289-.477-.242-.636l-.288.256a5.366 5.366 0 0 0-1.861 4.035 5.306 5.306 0 0 0 .894 2.957c.083.126.13.224.115.288s-.429 2.236-.429 2.236a.289.289 0 0 0 .1.278.294.294 0 0 0 .184.065.259.259 0 0 0 .1-.022l2.023-.8a.563.563 0 0 1 .433.007 6.069 6.069 0 0 0 2.189.433 5.663 5.663 0 0 0 4.331-1.987l.249-.346c-.132.05-.063-.302-.247-.259z"
        transform="translate(-2.625 -7.267)"
      />
    </g>
  </SvgIcon>
);

const QuestionIcon = () => (
  <SvgIcon inheritViewBox>
    <path
      data-name="Icon ionic-md-help"
      d="M13.235 21.62h2.943v-2.9h-2.943zm.045-4.369h2.853c0-4.5-4.28-4.24-4.28-7.089a2.839 2.839 0 0 1 2.853-2.827 2.912 2.912 0 0 1 2.853 2.871h2.853A5.707 5.707 0 0 0 9 10.162c0 3.562 4.28 3.968 4.28 7.089z"
      transform="translate(-9 -4.5)"
    />
  </SvgIcon>
);

const LogoutIcon = () => (
  <SvgIcon inheritViewBox>
    <g data-name="Iconly/Bold/Logout">
      <path
        data-name="Path 939"
        d="M7.309 0a3.307 3.307 0 0 1 3.47 3.1v3.352h-4.7a.542.542 0 1 0 0 1.077h4.7v3.342a3.317 3.317 0 0 1-3.486 3.111H3.478A3.309 3.309 0 0 1 0 10.878V3.111A3.317 3.317 0 0 1 3.486 0h3.823zm5.426 4.579a.631.631 0 0 1 .839-.007l2.248 2.034a.511.511 0 0 1 .178.385.5.5 0 0 1-.177.377L13.575 9.4a.628.628 0 0 1-.839 0 .506.506 0 0 1 0-.762l1.232-1.112H10.78V6.453h3.188l-1.233-1.112a.506.506 0 0 1 0-.762z"
        transform="translate(.244)"
      />
    </g>
  </SvgIcon>
);

const NotificationIcon = () => (
  <SvgIcon inheritViewBox>
    <path d="M2.125 9q0-1.771.75-3.333Q3.625 4.104 5 3l1.083 1.083q-1.166.875-1.812 2.167Q3.625 7.542 3.625 9Zm14.25 0q0-1.458-.646-2.75t-1.812-2.167L15 3q1.375 1.104 2.125 2.667.75 1.562.75 3.333ZM4 15.5V14h1V9q0-1.792 1.115-3.177Q7.229 4.438 9 4.104V3q0-.417.292-.708Q9.583 2 10 2t.708.292Q11 2.583 11 3v1.104q1.771.334 2.885 1.708Q15 7.188 15 9v5h1v1.5Zm6 2.5q-.625 0-1.062-.438Q8.5 17.125 8.5 16.5h3q0 .625-.438 1.062Q10.625 18 10 18Z" />
  </SvgIcon>
);

export {
  ProfileIcon,
  RequestIcon,
  HeartIcon,
  VerifiedIcon,
  EyeIcon,
  ChatIcon,
  QuestionIcon,
  LogoutIcon,
  NotificationIcon,
};
