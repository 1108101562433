import React, { useState, useEffect } from "react";
import {
  CustomHomeIcon,
  CustomMapPinIcon,
  CustomMessagesIcon,
  CustomPlusIcon,
  CustomProfileIcon,
  FooterButton,
  FooterMenuStyled,
  IconWrapper,
} from "./style";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import { useProperty } from "../../../PropertyWithFilter/context";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Image from "next/image";
import { useAuth } from "../../../../context/auth";
import { isAndroid, isIOS } from "react-device-detect";

const FooterMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation("common");
  const router = useRouter();
  const {
    mapView,
    setMapView,
    followingData,
    setFollowingData,
    tab,
    setTab,
    propertyData,
    setPropertyData,
  } = useProperty();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = anchorEl;
  const id = open ? "new-post-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { user } = useAuth();

  useEffect(() => {
    router.events.off("routeChangeStart", handleClose());
    if (router?.query?.view == "map") {
      setMapView("map");
    }
  }, []);

  return (
    <FooterMenuStyled
      visible={
        router?.pathname == "/" ||
        router?.pathname == "/profile" ||
        router?.pathname == "/messages"
      }
    >
      <FooterButton
        active={router?.pathname == "/" && mapView == "list" && !open}
      >
        <IconWrapper
          active={router?.pathname == "/" && mapView == "list" && !open}
          onClick={() => {
            router?.push("/");
            setMapView("list");
          }}
          iconW={20}
          iconH={24}
        >
          <CustomHomeIcon />
        </IconWrapper>
        {router?.pathname == "/" && mapView == "list" && !open && (
          <Typography
            variant="body2"
            size="smaller"
            sx={{ color: "primary.main", mt: "5px" }}
          >
            {t("home_page")}
          </Typography>
        )}
      </FooterButton>
      <FooterButton
        active={router?.pathname == "/" && mapView == "map" && !open}
      >
        <IconWrapper
          iconW={19}
          iconH={24}
          active={router?.pathname == "/" && mapView == "map" && !open}
          onClick={() => {
            router?.push({ pathname: "/", query: { view: "map" } });
            // setMapView("map");
            followingData == true ? setTab("all") : setMapView("map");
            if (followingData == true) {
              setPropertyData(true);
              setFollowingData(false);
            }
          }}
        >
          <CustomMapPinIcon />
        </IconWrapper>
        {router?.pathname == "/" && mapView == "map" && !open && (
          <Typography
            variant="body2"
            size="smaller"
            sx={{ color: "primary.main", mt: "5px" }}
          >
            {t("map")}
          </Typography>
        )}
      </FooterButton>
      <FooterButton active={open}>
        <IconWrapper iconW={24} iconH={24} onClick={handleClick} active={open}>
          <CustomPlusIcon />
        </IconWrapper>
        {open && (
          <Typography
            variant="body2"
            size="smaller"
            sx={{ color: "primary.main", mt: "5px" }}
          >
            {t("post")}
          </Typography>
        )}
      </FooterButton>
      <FooterButton active={router?.pathname == "/messages" && !open}>
        <Link href={!user ? "/login" : "/messages"}>
          <IconWrapper
            iconW={24}
            iconH={24}
            active={router?.pathname == "/messages" && !open}
          >
            <CustomMessagesIcon />
          </IconWrapper>
        </Link>
        {router?.pathname == "/messages" && !open && (
          <Typography
            variant="body2"
            size="smaller"
            sx={{ color: "primary.main", mt: "5px" }}
          >
            {t("messages")}
          </Typography>
        )}
      </FooterButton>
      <FooterButton active={router?.pathname == "/profile" && !open}>
        <Link href={!user ? "/login" : "/profile"}>
          <IconWrapper active={router?.pathname == "/profile" && !open}>
            <CustomProfileIcon />
          </IconWrapper>
        </Link>
        {router?.pathname == "/profile" && !open && (
          <Typography
            variant="body2"
            size="smaller"
            sx={{ color: "primary.main", mt: "5px" }}
          >
            {t("profile")}
          </Typography>
        )}
      </FooterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        className="post_request_popover"
      >
        <Box sx={{ py: "18px", px: "14px", minWidth: "230px" }}>
          <Stack direction={"column"} spacing={2}>
            <Link
              href={
                !user
                  ? "/login"
                  : user?.userType == 1000
                  ? "/sign-up/owner-type"
                  : "/add-property"
              }
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" size="small" sx={{ color: "#000" }}>
                  {t("add_new_property")} <span style={{ color: "#00BEAD" }}>{t("Free")}  </span>
                </Typography>
                <Image
                  src={require("../icons/property-icon.svg")}
                  alt="Property icon"
                  width={20}
                  height={20}
                />
              </Box>
            </Link>
            <Link href={!user ? "/login" : "/my-request/add-new-request"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" size="small" sx={{ color: "#000" }}>
                  {t("request_a_property")}
                </Typography>
                <Image
                  src={require("../icons/request-icon.svg")}
                  alt="Property icon"
                  width={20}
                  height={20}
                />
              </Box>
            </Link>
          </Stack>
        </Box>
      </Popover>
    </FooterMenuStyled>
  );
};

export default FooterMenu;
