import residential from '../../../../../public/icons/property/u_residential.svg';
import commercial from '../../../../../public/icons/property/u_suitcase-alt.svg';
import industrial from '../../../../../public/icons/property/u_ Factory.svg';
import medical from '../../../../../public/icons/property/u_medical.svg';
import agriculture from '../../../../../public/icons/property/u_agriculture.svg';
import education from '../../../../../public/icons/property/u_graduation-cap.svg';

export const classificationList = [
    {
        title: "residential",
        icon: residential,
        value: "residential",
        id: 0
    },
    {
        title: "commercial",
        icon: commercial,
        value: "commercial",
        id: 5
    },
    {
        title: "industrial",
        icon: industrial,
        value: "industrial",
        id: 15
    },
    {
        title: "medical",
        icon: medical,
        value: "medical",
        id:20
    },
    {
        title: "agriculture",
        icon: agriculture,
        value: "agriculture",
        id: 10
    },
    {
        title: "education",
        icon: education,
        value: "education",
        id:25
    }
];