import { Typography } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useState } from "react";
import { CustomButton } from "../../../Buttons/Buttons";
import { LanguageSwitcherStyled } from "./style";
import { LanguageIcon } from "./style";

const LanguageSwitcher = () => {
  const router = useRouter();
  const { locales, locale: activeLocale } = router;

  const otherLocales = locales?.filter(
    (locale) => locale !== activeLocale && locale !== "default"
  );
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('language', router?.locale);
  }


  const handelLang = () =>{
    if (typeof window !== 'undefined') {
    window.localStorage.setItem('languageFlag', window.localStorage.getItem('languageFlag')  ? !window.localStorage.getItem('languageFlag') : true );
  }
  }

  return (
    <LanguageSwitcherStyled>
      <>
        {otherLocales?.map((locale) => {
          const { pathname, query, asPath } = router;
          return (
            <a
              key={"locale-" + locale}
              href={`${locale === "ar" ? asPath : "/en" + asPath}`}
            >
              <Typography variant="body1Toggle" sx={{ color: "primary.main" }} onClick={() => handelLang()}>
                {locale === "en"
                  ? "English"
                  : locale === "ar"
                    ? "العربية"
                    : null}
              </Typography>
            </a>
          );
        })}
        <LanguageIcon />
      </>
    </LanguageSwitcherStyled>
  );
};

export default LanguageSwitcher;
 