import React from "react";
import BuildingTypeTabs from "../../../common/BuildingTypeTabs";
import { PropertyTypeSwitcherStyled } from "./style";

const PropertyTypeSwitcher = () => {
  return (
    <PropertyTypeSwitcherStyled>
      <BuildingTypeTabs />
    </PropertyTypeSwitcherStyled>
  );
};

export default PropertyTypeSwitcher;
