import { useQuery } from "react-query";
import { useAxios } from "../../../../hooks/axios";

const useGetUnReadNotificationCount = ({ enabled, pushToken }) => {
  const axios = useAxios();
  return useQuery(
    ["notifications", "unread-count"],
    async () => {
      const { data } = await axios.get("/messages/v1/push/unreadcount", {
        params: {
          deviceToken: pushToken,
        },
      });

      return data;
    },

    { enabled: !!enabled, refetchInterval: 3 * (60 * 1000) } //3 minutes
  );
};
export default useGetUnReadNotificationCount;
