import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const AccountVerifiedOkStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  padding: `${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(15)}`,
}));

const LogoWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  width: "200px",
  marginBottom: theme.typography.pxToRem(24),
  [theme.breakpoints.up("sm")]: {
    width: "264px",
    marginBottom: theme.typography.pxToRem(48),
  },
  "& .company-logo": {
    objectFit: "contain",
    width: "100% !important",
    position: "relative !important",
    height: "unset !important",
  },
}));

const Info = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.typography.pxToRem(55),
}));

const Actions = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  width: "100%",
  maxWidth: "340px",
}));

const CloseButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  zIndex: 99,
  top: 0,
  right: 0,
}));

export {
  AccountVerifiedOkStyled,
  LogoWrapper,
  Info,
  Actions,
  CloseButtonWrapper,
};
