import styled from "@emotion/styled";
import { Container } from "@mui/material";
import isPropValid from "@emotion/is-prop-valid";

const ContainerStyled = styled(Container)`
  background-color: #fff;
  position: relative;
`;

const MainStyled = styled("main", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "solidBg",
})(({ theme, solidBg }) => ({
  flex: 1,
  minHeight: "80vh",
  position: "relative",
  background: `#F7F7F8`,
  background: solidBg
    ? "#F8F8F8"
    : `linear-gradient(
    176deg,
    rgba(252, 252, 252, 1) 5%,
    transparent 100%
  )`,
  // zIndex: 1,
  margin: `0 ${theme.typography.pxToRem(-14)}`,
  [theme.breakpoints.up("sm")]: {
    margin: `0 ${theme.typography.pxToRem(-24)}`,
  },
}));

const Content = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  zIndex: 2,
  padding: "0 15px 15px 15px",
  // overflow: "hidden",
  // marginBottom: "-80px",
}));

const GradientArtTop = styled.div`
  position: absolute;
  width: 227.6px;
  height: 269.1px;
  opacity: 0.18;
  border-radius: 120px;
  filter: blur(37.5px);
  background-image: linear-gradient(142deg, #00c4b3 4%, #0055b8 95%);
  z-index: 1;
`;

const FooterWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  paddingTop: "5px",
  margin: "0 -16px",
  [theme.breakpoints.up("sm")]: {
    margin: "0 -24px",
  },
}));

export { ContainerStyled, MainStyled, GradientArtTop, Content, FooterWrapper };
