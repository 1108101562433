import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { MdOutlineClose } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";

const AdvancedSearchStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "visible",
})(({ theme, visible }) => ({
  position: "fixed",
  top: 0,
  padding: `${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(
    15
  )} ${theme.typography.pxToRem(60)} ${theme.typography.pxToRem(15)}`,
  backgroundColor: "#fff",
  zIndex: 1000,
  left: 0,
  boxShadow: `1px 1px 18px -3px rgba(0,0,0,0.20)`,
  width: "100%",
  maxWidth: "350px",
  height: "100%",
  transition: "all 0.3s ease",
  transform: visible ? "translateX(0)" : "translateX(-100%)",
  opacity: visible ? 1 : 0,
  overflowY: "auto",
  overflowX: "hidden",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "430px",
  },
  
  // body: {
  //   overflow: visible ? "hidden" : "auto",
  // },
}));

const CloseButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "visible",
})(({ theme, visible }) => ({
  position: "absolute",
  top: 5,
  right: 5,
}));

const CloseButton = styled(MdOutlineClose, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "visible",
})(({ theme, visible }) => ({
  color: theme.palette.text.main,
  fontSize: theme.typography.pxToRem(20),
}));

const CustomAutoComplete = styled(Autocomplete)({
  // "& .MuiAutocomplete-input": {
  //   fontSize: "12px",
  //   color: "#8e8e8e",
  //   padding: "0 !important",
  // },
  // "& .mui-style-ltr-1d2iad-MuiInputBase-root-MuiOutlinedInput-root": {
  //   borderRadius: "10px",
  // },
  // "& .mui-style-rtl-1pvu85l-MuiInputBase-root-MuiOutlinedInput-root": {
  //   borderRadius: "10px",
  // },
});

const ListingTypes = styled("ul", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && prop !== "centered" && prop !== "narrow",
})(({ theme, centered, narrow }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: centered ? "center" : "flex-start",
  padding: 0,
  margin: 0,
  margin: narrow ? "-8px" : "-18px",
}));

const ItemWithIcon = styled("li", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && prop !== "active" && prop !== "narrow",
})(({ theme, active, narrow }) => ({
  listStyleType: "none",
  margin: narrow ? "5px" : "10px",
  padding: "10px 15px",
  border: "1px solid #f1f1f1",
  borderRadius: "5px",
  cursor: "pointer",
  transition: "background 0.2s ease",
  transition: "border 0.2s ease",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: active ? theme.palette.primary.main : "#fff",
  "& p": {
    color: active ? "#fff" : theme.palette.text.main,
  },
  "& svg": {
    color: active ? "#fff" : theme.palette.text.main,
  },
  "&:hover": {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
  },
  "&:hover p": {
    color: "#fff",
  },
  "&:hover svg": {
    color: "#fff",
  },
}));
const MinMaxTag = styled("div", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && prop !== "active" && prop !== "narrow",
})(({ theme, active, narrow }) => ({
  padding: "12px 12px",
  border: "1px solid #C4C4C4",
  borderRadius: "5px",
  width: "100%",
}));

const IconWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) &&
    prop !== "visible" &&
    prop !== "width" &&
    prop !== "height",
})(({ width, height }) => ({
  marginBottom: "5px",
  "& svg": {
    height: `${width}px`,
    width: `${height}px`,
  },
}));



export {
  AdvancedSearchStyled,
  CloseButtonWrapper,
  CloseButton,
  CustomAutoComplete,
  ListingTypes,
  ItemWithIcon,
  IconWrapper,
  MinMaxTag,
};
