import React from "react";
import Image from "next/image";
import { Avatar, SignUpLink, UserStyled } from "./style";
import AvatarImage from "../../../../../../public/images/default-avatar.jpg";
import { useTranslation } from "next-i18next";
import { useAuth } from "../../../../../context/auth";
import Typography from "@mui/material/Typography";
import Link from "next/link";

const User = () => {
  const { user, logout } = useAuth();
  const { t } = useTranslation("common");
  const handleLogout = () => {
    logout();
  };
// console.log('user' ,user);
  // Update agent profile url ,add FAL NUMBER to agent profile url
  // let licenseNumber;
  // let userTypeText;

  // if (typeof window !== 'undefined') {
  //   licenseNumber = window.localStorage.getItem('licenseNumber');
  //   userTypeText = window.localStorage.getItem('userTypeText');
  // }

  return (
    <UserStyled>
      {/* <Link
        component={StyledLink}
        href={!user ? "/login" : "/profile"}
        className="textdecorationnone"
      >
        {user ? (
          <span>
            {user?.firstName
              ? user.firstName
              : user.email
              ? user?.email
              : t("welcome")}
          </span>
        ) : (
          t("login_signup")
        )}
      </Link> */}

      {/* {user && (
        <Link
          component={StyledLink}
          href="#"
          underline="none"
          color="primary"
          variant="link"
          size="default"
        >
          <a
            onClick={() => {
              handleLogout();
            }}
          >
            {t("sign_out")}
          </a>
        </Link>
      )} */}
      {user ? (
        <Link href={"/profile"}>
          <Avatar>
            <Image
              src={user?.avatarSourceUrl ? user?.avatarSourceUrl : AvatarImage}
              alt={t("default_avatar_image")}
              fill
              priority={true}
              style={{ objectFit: "cover", objectPosition: "center" }}
            />
          </Avatar>
        </Link>
      ) : (
        <Link href={"/login"}>
          <SignUpLink>
            <Typography variant="body1" sx={{ color: "#fff" }}>
              {t("sign_in")}
            </Typography>
          </SignUpLink>
        </Link>
      )}
    </UserStyled>
  );
};

export default User;
