import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const CustomSearchWithFilterButtonStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isSearchExpand",
})(({ theme, isSearchExpand }) => ({
  position: "relative",
  flexGrow: isSearchExpand ? 1 : 0,
  display: "flex",
  alignItems: "center",
  margin: isSearchExpand ? `0 ${theme.typography.pxToRem(5)}` : 0,
  [theme.breakpoints.up("md")]: {
    flexGrow: 1,
    margin: `0 ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(
      20
    )}`,
  },
  [theme.breakpoints.up("lg")]: {
    margin: `0 ${theme.typography.pxToRem(25)}`,
  },
  [theme.breakpoints.up("xl")]: {
    margin: `0 ${theme.typography.pxToRem(64)}`,
  },
}));

const FilterButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isSearchExpand",
})(({ theme, isSearchExpand }) => ({
  display: isSearchExpand ? "block" : "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
  "& button": {
    border: "none",
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(
      12
    )} ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(12)}`,
    borderRadius: "0px 10px 10px 0px",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(
        12
      )} ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(12)}`,
    },
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(
        19
      )} ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(19)}`,
    },
  },
}));



const SearchAreaWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isSearchExpand",
})(({ theme, isSearchExpand }) => ({
  display: "flex",
  border: isSearchExpand ? "1px solid #EFF0FA" : "none",
  padding: isSearchExpand
    ? `0 ${theme.typography.pxToRem(8)} 0 ${theme.typography.pxToRem(8)}`
    : 0,
  borderRadius: "10px 0px 0px 10px",
  flexGrow: isSearchExpand ? 1 : 0,
  height: "38px",
  [theme.breakpoints.up("md")]: {
    padding: `0 ${theme.typography.pxToRem(5)} 0 ${theme.typography.pxToRem(
      25
    )}`,
    border: "1px solid #EFF0FA",
    flexGrow: 1,
  },
  "& .MuiAutocomplete-endAdornment": {
    display: "none",
  },
  "& .MuiAutocomplete-root": {},
  "& .MuiOutlinedInput-root": {
    height: "32px",
    padding: "0px  !important",
    "& fieldset": {
      padding: "0px !important",
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px !important",
    },
  },
}));

const SearchButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const SearchFieldWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isSearchExpand",
})(({ theme, isSearchExpand }) => ({
  flexGrow: 1,
  display: isSearchExpand ? "block" : "none",
  "& .MuiAutocomplete-root": {
    marginTop: "1px",
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const FilterContainer = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isFilterOpen",
})(({ theme, isFilterOpen, isSearchExpand }) => ({
  position: "absolute",
  width: "100%",
  maxWidth: "500px",
  minHeight: "200px",
  bottom: 0,
  right: 0,
  transition: "opacity 0.3s ease",
  opacity: isFilterOpen ? 1 : 0,
  transform: isFilterOpen ? "translate(0, 100%)" : "translate(0, -100%)",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
  padding: "10px",
  borderRadius: "10px",
}));

const FilterAnchor = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isSearchExpand",
})(({ theme, isSearchExpand }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  width: "100%",
  height: "5px",
  outline: "1px solid green",
}));

export {
  CustomSearchWithFilterButtonStyled,
  FilterButtonWrapper,
  SearchButtonWrapper,
  SearchAreaWrapper,
  SearchFieldWrapper,
  FilterContainer,
  FilterAnchor,
};
