import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const BuildingTypeTabsStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const PropertyIcon = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  marginBottom: "3px",
  height: "30px",
  width: "30px",
  // outline: "1px solid red",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  "& svg": {},
}));

const Tabs = styled("ul", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const Tab = styled("li", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "active",
})(({ theme, active }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(10)}`,
  margin: "2px",
  listStyleType: "none",
  cursor: "pointer",
  textAlign: "center",
  backgroundColor: active ? theme.palette.primary.main : "transparent",
  // border: active
  //   ? `1px solid ${theme.palette.primary.main}`
  //   : `1px solid ${theme.palette.lightGray.main}`,
  borderRadius: "5px",
  transition: "background 0.2s ease",
  "& p": {
    transition: "color 0.2s ease",
    color: active ? theme.palette.white.main : theme.palette.midGray.main,
  },
  "& svg": {
    transition: "color 0.2s ease",
    color: active ? theme.palette.white.main : theme.palette.midGray.main,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    // borderColor: theme.palette.primary.main,
  },
  "&:hover p": {
    color: theme.palette.white.main,
  },
  "&:hover svg": {
    color: theme.palette.white.main,
  },
  [theme.breakpoints.up("sm")]: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
  },
}));

export { BuildingTypeTabsStyled, Tabs, Tab, PropertyIcon };
