import { useForm } from "react-hook-form";
import { useTranslation } from "next-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useAdvanceSearchForm = () => {
  const { t } = useTranslation("common");
  const schema = yup.object({
    // city_id: yup.object(),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  return {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    isSubmitting,
    clearErrors,
  };
};

export default useAdvanceSearchForm;
