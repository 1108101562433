import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const UserStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.typography.pxToRem(20),
  "& .textdecorationnone": {
    textDecoration: "none",
  },
  "& span": {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
  },
  [theme.breakpoints.up("lg")]: {
    marginLeft: theme.typography.pxToRem(50),
  },
}));

const Avatar = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  width: "35px",
  height: "35px",
  overflow: "hidden",
  borderRadius: "50%",
  marginLeft: theme.typography.pxToRem(12),
  [theme.breakpoints.up("sm")]: {
    width: "50px",
    height: "50px",
  },
}));

const UserActionLink = styled("a", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: `${theme.typography.pxToRem(16)}`,
  color: theme.palette.primary.main,
  fontFamily: theme.typography.link.fontFamily,
}));

const SignUpLink = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: "4px",
  padding: "8px",
}));

export { UserStyled, Avatar, UserActionLink, SignUpLink };
