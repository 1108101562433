import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const SwiperStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  "& .swiper-slide": {
    width: "auto",
  },
}));

export { SwiperStyled };
