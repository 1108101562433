import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
// import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useProperty } from "../../../PropertyWithFilter/context";
import { SwitcherTab, SwitcherTabs, ViewSwitcherStyled } from "./style";

const ViewSwitcher = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { mapView, setMapView } = useProperty();

  return (
    <ViewSwitcherStyled>
      <SwitcherTabs>
        <SwitcherTab
          active={mapView == "list"}
          onClick={() => {
            setMapView("list");
            router.push("/advance-search");
          }}
        >
          <Typography variant="body1" sx={{ color: "main.primary" }}>
            {t("list")}
          </Typography>
        </SwitcherTab>
        <SwitcherTab
          active={mapView == "map"}
          onClick={() => {
            setMapView("map");
            // router.push("/map/search");
          }}
        >
          {/* <Link href="/map/search"> */}
          <Typography variant="body1" sx={{ color: "main.primary" }}>
            {t("map")}
          </Typography>
          {/* </Link> */}
        </SwitcherTab>
      </SwitcherTabs>
    </ViewSwitcherStyled>
  );
};

export default ViewSwitcher;
