import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
import { BuildingTypeTabsStyled, PropertyIcon, Tab, Tabs } from "./style";
import * as _ from "lodash";
import { useRouter } from "next/router";
import {
  BuildingIcon,
  ApartmentIcon,
  RoofIcon,
  VillaIcon,
  LandIcon,
} from "./icons";
import { useProperty } from "../../PropertyWithFilter/context";

const BuildingTypeTabs = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { propertyType, setPropertyType, handleClear, mapView } = useProperty();

  const tabs = [
    {
      title: t("apartment"),
      value: "apartment",
      icon: <ApartmentIcon />,
    },
    {
      title: t("villa"),
      value: "villa",
      icon: <VillaIcon />,
    },
    {
      title: t("building"),
      value: "building",
      icon: <BuildingIcon />,
    },

    {
      title: t("land"),
      value: "land",
      icon: <LandIcon />,
    },

    {
      title: t("roof"),
      value: "roof",
      icon: <RoofIcon />,
    },
  ];

  return (
    <BuildingTypeTabsStyled>
      <Tabs>
        {_.map(tabs, (tab, index) => (
          <Tab
            key={`building-type-${index}`}
            active={propertyType == tab.value}
            onClick={() => {
              handleClear();
              setPropertyType(tab.value);
              if (mapView == "map") {
                router.push(`/advance-search`);
              } else {
                router.push(`/advance-search`);
              }
            }}
          >
            <PropertyIcon>{tab.icon}</PropertyIcon>
            <Typography variant="body1">{tab.title}</Typography>
          </Tab>
        ))}
      </Tabs>
    </BuildingTypeTabsStyled>
  );
};

export default BuildingTypeTabs;
