import React from "react";
import Stack from "@mui/material/Stack";
import CustomSearchWithButton from "../../../common/CustomSearchWithButton";
import { CustomButton } from "../../../../components/common/Buttons/Buttons";
import { useTranslation } from "next-i18next";
import { FilterIcon } from "./style";

const PropertySearch = ({ setMenuVisible, menuVisible }) => {
  const { t } = useTranslation("common");

  return (
    <Stack direction="row" spacing={4}>
      <CustomSearchWithButton />
      <CustomButton
        onClick={() => setMenuVisible(!menuVisible)}
        variant="contained"
      >
        <FilterIcon /> {t("filter")}
      </CustomButton>
    </Stack>
  );
};

export default PropertySearch;
