import React from "react";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import {
  AccountVerifiedOkStyled,
  Actions,
  CloseButtonWrapper,
  Info,
  LogoWrapper,
} from "./style";
import Stack from "@mui/system/Stack";
import { CustomButton } from "../common/Buttons/Buttons";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const AccountVerifiedOk = () => {
  return (
    <AccountVerifiedOkStyled>
      <CloseButtonWrapper>
        <IconButton onClick={() => {}}>
          <CloseIcon />
        </IconButton>
      </CloseButtonWrapper>
      <LogoWrapper>
        <Image
          src={require("../../../public/images/header-logo.svg")}
          alt="Logo"
          fill
          className="company-logo"
        />
      </LogoWrapper>
      <Info>
        <Typography variant="body2" size="biggest" sx={{ mb: "22px" }}>
          Your account has been verified successfully
        </Typography>
        <Typography variant="body2" size="extraBig" sx={{ color: "#46464A" }}>
          You can now start posting your property
        </Typography>
      </Info>
      <Actions>
        <Stack direction={"column"} spacing={3}>
          <CustomButton fluid variant="contained">
            Add Property
          </CustomButton>
          <CustomButton fluid variant="outlined">
            Close
          </CustomButton>
        </Stack>
      </Actions>
    </AccountVerifiedOkStyled>
  );
};

export default AccountVerifiedOk;
