import { useQuery } from "react-query";
import { useAuth } from "../../../context/auth";
import { useAxios } from "../../../hooks/axios";

const useGetProfile = () => {
  const { user } = useAuth();
  const axios = useAxios();
  return useQuery(["buyer", "profile"], async () => {
    // if (user) {
    const { data } = await axios.get(`/profiles/v1`);
    return data?.data;
    // }
  });
};

export { useGetProfile };
