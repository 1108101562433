import React from "react";
import AdvancedSearch from "./components/AdvancedSearch/AdvancedSearch";
import PropertySearch from "./components/PropertySearch/PropertySearch";
import PropertyTypeSwitcher from "./components/PropertyTypeSwitcher";
import ViewSwitcher from "./components/ViewSwitcher/ViewSwitcher";
import { AdvancedSearchHeaderStyled, Divider } from "./style";


const AdvancedSearchHeader = ({ setMenuVisible, menuVisible }) => {
  return (
    <AdvancedSearchHeaderStyled>
      <PropertySearch
        setMenuVisible={setMenuVisible}
        menuVisible={menuVisible}
      />
      <PropertyTypeSwitcher />
      <ViewSwitcher />
      {menuVisible && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            // overflow: "hidden",
          }}
        >
          
          <AdvancedSearch visible={menuVisible} setVisible={setMenuVisible} />
        </div>
      )}
      <Divider />
    </AdvancedSearchHeaderStyled>
  );
};

export default AdvancedSearchHeader;
