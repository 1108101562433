import React, { useEffect, useState } from "react";
import Brand from "./components/Brand/Brand";
import LanguageSwitcher from "./components/LanguageSwitcher";
import Notifications from "./components/Notifications";
import User from "./components/User/User";
import {
  HeaderStyled,
  HeaderLeft,
  HeaderRight,
  HeaderCenter,
  CenterNavBlock,
  MenuButton,
  HeaderLinkOutlined,
  HeaderStyledNew,
  HeaderCenterNew,
  HeaderRightNew,
  HeaderLeftNew,
  UserActionsWrapperNew,
  PropertyActionsWrapperNew,
  PropertyActionLink,
  HeaderStyledNewMain,
} from "./style";
import { useTranslation } from "next-i18next";
import Stack from "@mui/material/Stack";
import NoSsr from "@mui/material/NoSsr";
import { Grid, Typography } from "@mui/material";
import { useAuth } from "../../../context/auth";
import CustomSearchWithFilterButton from "../../CustomSearchWithFilterButton/CustomSearchWithFilterButton";
import { CustomButton } from "../Buttons/Buttons";
import Messages from "./components/Messages";
import UserNew from "./components/UserNew";
import Box from "@mui/system/Box";
import Cookies from "js-cookie";
import Link from "next/link";
import Image from "next/image";
import { AiOutlineClose } from 'react-icons/ai';
import { isIOS, isMobile } from "react-device-detect";
import { useRouter } from "next/router";
import moment from "moment";
import { parseJwt } from "../../../utils/jwt";

const Header = ({ menuVisible, setMenuVisible }) => {

  const router = useRouter();
  const { locales, locale: activeLocale } = router;
  const isCookieBanner = Cookies.get("@isCookieBanner");

  const { t } = useTranslation("common");
  const [sideMenuVisible, setsSideMenuVisible] = useState(false);
  const { user } = useAuth();
  const [isSearchExpand, setIsSearchExpand] = useState(false);
  const [isDownloadAppShow, setIsDownloadAppShow] = useState(false);
  const [canAddListing, setCanAddListing] = useState(false);

  const iosLink = "https://apps.apple.com/us/app/sanadak-%D8%B3%D9%86%D8%AF%D9%83/id1626116884";
  const androidLink = "https://play.google.com/store/apps/details?id=com.smartappholding.sanadak&hl=en&gl=US";

  const openAndroidApp = 'sanadak://com.sanadak'
  // const openAndroidApp = "https://sanadak.onelink.me/details?id=com.smartappholding.sanadak&hl=en&gl=US";

  const openIosApp = 'sanadak://'
  // const openIosApp = "https://sanadak.onelink.me/sanadak-%D8%B3%D9%86%D8%AF%D9%83/id1626116884";

  useEffect(() => {
    if (isCookieBanner == undefined) {
      setIsDownloadAppShow(true);
    }
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem('access_token') != null) {
      const jwt = parseJwt(window.localStorage.getItem('access_token'));
      setCanAddListing(jwt.canAddStory == "True" ? true : false);
    }
  }, [canAddListing]);

  return (
    <HeaderStyledNewMain>
      {(isDownloadAppShow && isMobile) && (
        <div style={{
          paddingTop: 20,
          paddingBottom: 15,
          paddingLeft: 15,
          paddingRight: 15,
          marginLeft: -16,
          marginRight: -16,
          backgroundColor: "#f5f6fa"
        }}>

          {/* <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'space-between' }} > */}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item xs={6}>
              <p style={{ marginInlineStart: '7px', fontSize: 14, fontWeight: 'bold', marginBottom: '7px' }}>{t("use_sanadak_app")}</p>
            </Grid>

            {/* <div style={{ display: 'flex',marginLeft:'-14px'}}> */}
            {/* <Grid item xs> */}

            {/* <Link href={isIOS ?(openIosApp? openIosApp : iosLink )  : (openAndroidApp?openAndroidApp:androidLink)} target="_blank"> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Link href={isIOS ? iosLink : androidLink} target="_blank">
                <CustomButton variant="contained" style={{ margin: '7px', paddingBottom: '15px', paddingTop: '15px', borderRadius: '11px', fontWeight: 'bold', fontSize: isIOS ? 14 : 11 }}>
                  {t("go_to_app")}
                </CustomButton>
              </Link>

              &nbsp;
              <AiOutlineClose size={25} onClick={() => {
                setIsDownloadAppShow(false);
                Cookies.set("@isCookieBanner", moment());
              }} style={{ marginLeft: '-9px', cursor: 'pointer' }} />
            </div>
            {/* </Grid> */}

            {/* <Grid item xs style={{ textAlign: activeLocale == "en" ? 'right' : 'left' }}> */}
            {/* <Image 
                src="/images/sanadak-app-icon.svg"
                width={25}
                height={25}
                 /> */}

            {/* </Grid> */}
            {/* </div> */}

            {/* </Grid> */}
          </div>
        </div>
      )
      }

      <HeaderStyledNew>
        <HeaderLeftNew isSearchExpand={isSearchExpand}>
          <MenuButton
            visible={sideMenuVisible}
            onClick={() => setsSideMenuVisible(!sideMenuVisible)}
          >
            <span></span>
            <span></span>
            <span></span>
          </MenuButton>
          <Brand />
        </HeaderLeftNew>
        <HeaderCenterNew>
          <CustomSearchWithFilterButton
            isSearchExpand={isSearchExpand}
            setIsSearchExpand={setIsSearchExpand}
            menuVisible={menuVisible}
            setMenuVisible={setMenuVisible}
          />
          <PropertyActionsWrapperNew>
            <Stack direction={"row"} spacing={{ md: 1, lg: 2 }}>
              <Link
                href={
                  // !user
                  //   ? "/login"
                  //   : user?.userType == 1000
                  //     ? "/sign-up/owner-type"
                  //     : canAddListing ? "/add-property" : "/sign-up/owner-type"
                  !user
                    ? "/login"
                    : canAddListing ? "/add-property" : "/sign-up/owner-type"
                }
              >
                <PropertyActionLink>
                  <Typography variant="body1" sx={{ color: "primary.main" }}>
                    {t("add-property")}
                  </Typography>
                </PropertyActionLink>
              </Link>

              <Link href={!user ? "/login" : "/my-request/add-new-request"}>
                <PropertyActionLink>
                  <Typography variant="body1" sx={{ color: "primary.main" }}>
                    {t("request-property")}
                  </Typography>
                </PropertyActionLink>
              </Link>
              <Link href="/financing">
                <PropertyActionLink>
                  <Typography variant="body1" sx={{ color: "primary.main" }}>
                    {t("Request a financing account")}
                  </Typography>
                </PropertyActionLink>
              </Link>
            </Stack>
          </PropertyActionsWrapperNew>
          <UserActionsWrapperNew isSearchExpand={isSearchExpand}>
            <Stack
              direction={"row"}
              spacing={{ xs: 2, lg: 3 }}
              alignItems={"center"}
            >
              {!isMobile && (
                <Link href={!user ? "/login" : "/messages"}>
                  <Messages />
                </Link>
              )}
              <Notifications />
              <UserNew />
            </Stack>
          </UserActionsWrapperNew>
        </HeaderCenterNew>

        <HeaderRightNew visible={sideMenuVisible}>
          <LanguageSwitcher />
          <Box
            sx={{
              display: ["block", "block", "block", "none"],
              mt: "15px",
              borderTop: "1px solid #ccc",
              paddingTop: "15px",
            }}
          >
            <Stack direction={"column"} spacing={2}>
              <Link
                href={
                  !user
                    ? "/login"
                    : user?.userType == 1000
                      ? "/sign-up/owner-type"
                      : "/add-property"
                }
              >
                <PropertyActionLink>
                  <Typography variant="body1" sx={{ color: "primary.main" }}>
                    {t("add-property")}
                  </Typography>
                </PropertyActionLink>
              </Link>
              <Link href={!user ? "/login" : "/my-request/add-new-request"}>
                <PropertyActionLink>
                  <Typography variant="body1" sx={{ color: "primary.main" }}>
                    {t("request-property")}
                  </Typography>
                </PropertyActionLink>
              </Link>
              <Link href="/financing">
                <PropertyActionLink>
                  <Typography variant="body1" sx={{ color: "primary.main" }}>
                    {t("Request a financing account")}
                  </Typography>
                </PropertyActionLink>
              </Link>
            </Stack>
          </Box>
        </HeaderRightNew>
      </HeaderStyledNew>
    </HeaderStyledNewMain >
  );
};

export default Header;
