import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const BrandStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const BrandIcon = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  width: "39px",
  height: "48px",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    width: "130px",
    height: "60px",
  },
}));

export { BrandStyled, BrandIcon };
