import React, { useState, useEffect } from "react";
import {
  AppStoresBlock,
  ContainerStyled,
  Copyright,
  FooterContent,
  FooterInfoBlock,
  FooterInfoBlockContent,
  FooterInfoBlockHeader,
  FooterStyled,
  LinksBlock,
  LinksBlocks,
  Logo,
  LogoBlock,
  SocialMedia,
  SocialMedias,
} from "./style";
import Image from "next/image";
import FooterLogo from "../../../../public/images/footer-logo.png";
import InstagramLogo from "../../../../public/images/footer-instagram-icon.png";
import TwitterLogo from "../../../../public/images/footer-twitter-icon.png";
import LinkedInLogo from "../../../../public/images/footer-linkedin-icon.png";
import { rgbDataURL } from "../../../utils/utils";
import { useTranslation } from "next-i18next";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "next/link";
import _ from "lodash";
import { useRouter } from "next/router";
import moment from "moment";
import Box from "@mui/material/Box";
import FooterMenu from "./FooterMenu/FooterMenu";
import IconButton from "@mui/material/IconButton";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useProperty } from "../../PropertyWithFilter/context";
const Footer = () => {
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showButton, setShowButton] = useState(false);
  const {
    mapFullScreen,
  } = useProperty();

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowButton(true);
      window.removeEventListener('scroll', handleScroll);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const pageLinks = [
    { label: "home_page", link: "/" },
    { label: "about_us", link: "/about-us" },
    { label: "contact_us", link: "/contact-us" },
    // { label: "Financing", link: "/financing" },
  ];

  const termsLinks = [
    { label: "privacy", link: "/privacy-policy" },
    { label: "terms_of_us", link: "/terms-of-use" },
  ];

  return (
    <div style={{ position: "relative" }}>
      <FooterStyled
        morePadding={
          router?.pathname == "/" ||
          router?.pathname == "/profile" ||
          router?.pathname == "/messages"
        }
        style={{ position: "relative" }}
      >
        <FooterContent>
          <LogoBlock>
            <Logo>
              <Image
                src={require("./icons/footer-logo.svg")}
                alt={t("footer_logo")}
                width={130}
                height={60}
              />
            </Logo>
            <Stack direction={"row"} spacing={3}>
              <a
                href="https://www.linkedin.com/company/sanadaksa/"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={require("./icons/linkedin-icon.svg")}
                  alt={t("Instagram icon")}
                  width={26}
                  height={26}
                />
              </a>
              <a
                href="https://x.com/SanadakSA"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={require("./icons/x-icon.svg")}
                  alt={t("Instagram icon")}
                  width={26}
                  height={26}
                />
              </a>
              <a
                href="https://www.instagram.com/sanadaksa/"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={require("./icons/insta-icon.svg")}
                  alt={t("Instagram icon")}
                  width={26}
                  height={26}
                />
              </a>
            </Stack>
          </LogoBlock>

          <LinksBlocks>
            <Stack direction={"row"} spacing={{ xs: 3, sm: 4, md: 16 }}>
              <LinksBlock>
                <Typography
                  variant="h4Bold"
                  sx={{ color: "white.main", mb: "15px" }}
                >
                  {t("this_page")}
                </Typography>
                <Stack direction={"column"} spacing={1}>
                  {_.map(pageLinks, (item) => (
                    <Link href={item.link}>
                      <Typography
                        variant="body1"
                        size="smaller"
                        sx={{ color: "white.main" }}
                      >
                        {t(item.label)}
                      </Typography>
                    </Link>
                  ))}
                </Stack>
              </LinksBlock>
              <LinksBlock>
                <Typography
                  variant="h4Bold"
                  sx={{ color: "white.main", mb: "15px" }}
                >
                  {t("terms")}
                </Typography>
                <Stack direction={"column"} spacing={1}>
                  {_.map(termsLinks, (item) => (
                    <Link href={item.link}>
                      <Typography
                        variant="body1"
                        size="smaller"
                        sx={{ color: "white.main" }}
                      >
                        {t(item.label)}
                      </Typography>
                    </Link>
                  ))}
                </Stack>
              </LinksBlock>
            </Stack>
          </LinksBlocks>
          <AppStoresBlock>
            <Stack direction={"row"} spacing={2}>
              <Box
                sx={{
                  width: ["200px", "130px", "130px", "400px"],
                  height: ["100px", "48px", "48px", "160px"],
                  position: "relative",
                }}
              >
                <a
                  href="https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Bmlp/08daf884-583f-4070-8ead-57935b906eb2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./icons/fal.svg")}
                    alt={t("Instagram icon")}
                    style={{ objectFit: "contain" }}
                    fill
                  />
                </a>
                {(showButton && !mapFullScreen) && (
                  <IconButton
                    sx={{
                      p: 0,
                      ml: "8px",
                      position: "fixed",
                      bottom: isMobile ? "70px" : "20px", // Adjusted values for mobile
                      right: isMobile ? "10px" : "20px", // Adjusted values for mobile
                      zIndex: 99
                    }}
                    onClick={scrollToTop}

                  >
                    <Image
                      src={require("./icons/scroll-up.svg")}
                      width={35}
                      height={35}
                    />
                  </IconButton>
                )}
              </Box>
              <Stack
                spacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: ["100px", "130px", "130px", "177px"],
                    height: ["36px", "48px", "48px", "70px"],
                    position: "relative",
                  }}
                >
                  <a
                    href="https://apps.apple.com/us/app/id1626116884"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={require("./icons/app-store-icon.svg")}
                      alt={t("Instagram icon")}
                      fill
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                </Box>
                <Box
                  sx={{
                    width: ["100px", "130px", "130px", "177px"],
                    height: ["37px", "48px", "48px", "70px"],
                    position: "relative",
                  }}
                >
                  <a href="https://play.google.com/store/apps/details?id=com.smartappholding.sanadak"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={require("./icons/play-store-icon.svg")}
                      alt={t("Instagram icon")}
                      style={{ objectFit: "contain" }}
                      fill
                    />
                  </a>
                </Box>
              </Stack>
            </Stack>
          </AppStoresBlock>
        </FooterContent>

        <Copyright>
          {/* <p>Copyright © {moment().year()} Sanadak , www.sanadak.sa</p> */}
          <p>{t("footer_copyright")} © {moment().year()} {t("footer_content")}</p>
        </Copyright>
        <FooterMenu />
      </FooterStyled>
    </div>
  );
};

export default Footer;
