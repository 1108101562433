import { useQuery } from "react-query";
import { useAxios } from "./axios";
import { useAxiosSearch } from "./axiosSearch";
import Cookies from "js-cookie";
import { useTranslation } from "next-i18next";
export const useHeaderSearch = ({ filters, enabled, setSearchResultList }) => {
    const axios = useAxiosSearch();
    const { t } = useTranslation("common");
    return useQuery(
        ["search", "result", { ...filters }],
        async () => {
          if (filters.query) {
            setSearchResultList(null);
            
            let recent = [];
            let listings = [];
            let projects = [];
            let profiles = [];
            let districts = [];
            
            const { data } = await axios.get("/search/v1/main", {
              params: {
                query: filters?.query ?? "",
                pageSize: filters?.pageSize ?? "",
                page: 0,
              },
            });

            // listings
            if(data?.data.listings != undefined) {
                data?.data.listings.map(item => {
                    listings.push({
                        gTitle: "listing",
                        entityType: "listing",
                        ...item
                    });
                });
                // has more
                if(data?.data.listings.length > 4) {
                    listings.push({
                        gTitle: "listing",
                        entityType: "listing",
                        title:t("show_more"),
                    });
                }
            }

            // projects
            if(data?.data.projects != undefined) {
                data?.data.projects.map(item => {
                    projects.push({
                        gTitle: "projects",
                        entityType: "project",
                        ...item
                    });
                })
                // has more
                if(data?.data.projects.length > 4) {
                    projects.push({
                        gTitle: "projects",
                        entityType: "project",
                        title:t("show_more")
                    });
                }
            }

            // profiles
            if(data?.data.profiles != undefined) {
                data?.data.profiles.map(item => {
                    profiles.push({
                        gTitle: "agents",
                        entityType: "profile",
                        ...item
                    });
                })
                // has more
                if(data?.data.profiles.length > 4) {
                    profiles.push({
                        gTitle: "agents",
                        entityType: "profile",
                        title: t("show_more")
                    });
                }
            }

            // districts
            if(data?.data.districts != undefined) {
                data?.data.districts.map(item => {
                    districts.push({
                        gTitle: "areas",
                        entityType: "place",
                        ...item
                    });
                });
                // has more
                if(data?.data.districts.length > 4) {
                    districts.push({
                        gTitle: "areas",
                        entityType: "place",
                        title:t("show_more")
                    });
                }
            }

            //recent
            let searchValue = Cookies.get("recent_search");
            if (searchValue) {
                let searchArrays = JSON.parse(searchValue);
              
                // searchData = _.map(searchArrays, (item) => {
                //   return { entityType: "Recent search", title: item, id: item };
                // });
              }

            const finalArr = [...listings, ...projects, ...profiles, ...districts]
            setSearchResultList(finalArr);
            return data?.data;
          }
        },
        { enabled: !!enabled }
    );
};