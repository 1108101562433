import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { fetcherApi } from "../utils/utils";
import { useAxios } from "./axios";

//fetch all cities
const useGetCities = (filter) => {
  const axios = useAxios();
  return useQuery(["cities", { ...filter }], async () => {
    const { data } = await axios.get("areas/v1/cities", {
      params: {
        showAll: filter?.showAll ?? "",
      },
    });
    return data;
  });
};

/**
 *
 * @param {regionId} the regionid to fetch cities
 * @returns array of cities in that region
 */
const useGetCitiesByRegion = ({ regionId }) => {
  const axios = useAxios();
  return useQuery(["cities", "region", regionId], async () => {
    if (regionId) {
      const { data } = await axios.get(`areas/v1/${regionId}/cities`);
      return data;
    }
  });
};

//fetch Area Profile
const useAreaById = (areaId) => {
  const axios = useAxios();
  return useQuery(["areas", areaId], async () => {
    if (areaId) {
      const { data } = await axios.get(`areas/v1/${areaId}`);
      return data;
    }
  });
};

export const fetchAreaDetail = async (slug, locale = "en") => {
  const { data } = await fetcherApi(`areas/v1/${slug}`, locale);
  return data;
};

const useGetAllAreas = ({ areaId }) => {
  const axios = useAxios();
  const router = useRouter();

  return useQuery(["area-listing", areaId], async () => {
    if (areaId) {
      const { data } = await axios.get(`search/v1/Listings/${areaId}/area`, {
        northWestCoordinate: {
          Latitude: 0,
          Longitude: 0,
        },
        southEastCoordinate: {
          Latitude: 0,
          Longitude: 0,
        },
        page: 0,
        pageSize: 200,
        cityId: null,
        districtIds: areaId,
        sellerIds: [],
        listingType: null,
        propertyType: null,
        minPrice: 0,
        maxPrice: 0,
        minArea: 0,
        maxArea: 0,
        numberBedrooms: 0,
        numberBathrooms: 0,
        isOccupiedBefore: null,
        furnishType: null,
        orderByField: "date asc",

        headers: {
          "Accept-Language": router?.locale == "en" ? "en-US" : "ar-SA en-US",
        },
      });
      return data?.data;
    }
  });
};

/**
 *
 * @param {cityId} the cityId to fetch area
 * @returns array of area in that city
 */

const useAreaByCities = ({ selectedCity }) => {
  const axios = useAxios();
  return useQuery(["areas", "city", selectedCity], async () => {
    if (selectedCity) {
      const { data } = await axios.get("/areas/v1/", {
        params: {
          cityId: selectedCity ?? "",
        },
      });
      return data?.data;
    }
  });
};

export {
  useGetCities,
  useGetCitiesByRegion,
  useAreaById,
  useAreaByCities,
  useGetAllAreas,
};
