import React, { useState, useEffect } from "react";
import AdvancedSearchHeader from "../../components/AdvancedSearchHeader";
import AdvancedSearch from "../../components/AdvancedSearchHeader/components/AdvancedSearch";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import {
  ContainerStyled,
  Content,
  FooterWrapper,
  GradientArtTop,
  MainStyled,
} from "./style";
import { useProperty } from "../../components/PropertyWithFilter/context";
import { useRouter } from "next/router";

const PlainLayout = ({ children }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { mapFullScreen } = useProperty();
  const router = useRouter();

  return (
    <MainStyled>
      {/* <GradientArtTop /> */}
      <Content>{children}</Content>
    </MainStyled>
  );
};

export default PlainLayout;
