import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { padding } from "@mui/system";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

const SidebarLayoutStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  backgroundColor: "transparent",
  padding: "10px 0",
  // marginBottom: "-80px",
  minHeight: "80vh",
  zIndex: 2,
  [theme.breakpoints.up("md")]: {
    padding: "30px 30px 100px 30px",
  },
}));

const LeftBlock = styled("main", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  flex: 1,
  position: "relative",
  paddingBottom: "80px",
  [theme.breakpoints.up("md")]: {
    paddingBottom: 0,
  },

  // overflow: "hidden",
}));

const MenuFoldIcon = styled(AiOutlineMenuFold, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: "25px",
}));

const MenuUnfoldIcon = styled(AiOutlineMenuUnfold, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: "25px",
}));

const RightBlock = styled("aside", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "menuOpen",
})(({ theme, menuOpen }) => ({
  width: "220px",
  marginRight: "16px",
  overflow: "hidden",
  position: "absolute",
  zIndex: 999,
  padding: "5px",
  backgroundColor: "#fff",
  transition: "all 0.2s ease",
  transform: menuOpen ? "translateX(100)" : "translateX(-100%)",
  opacity: menuOpen ? 1 : 0,
  flexShrink: 0,
  [theme.breakpoints.up("md")]: {
    transform: "translateX(0)",
    opacity: 1,
    position: "relative",
    padding: "none",
    backgroundColor: "transparent",
  },
}));

const ProfileHeaderBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "12px",
  padding: "25px 15px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "10px",
  boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.1)",
  [theme.breakpoints.up("md")]: {
    boxShadow: "none",
  },
}));

const UserInfoBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const UserInfoBlockBottom = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  width: "100%",
}));

const UserInfoImageWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  width: "90px",
  height: "90px",
  borderRadius: "50%",
  marginBottom: theme.typography.pxToRem(12),
  overflow: "hidden",
}));

const SidebarMenuBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  padding: "10px 20px",
  backgroundColor: "#fff",
  borderRadius: "12px",
  boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.1)",
  [theme.breakpoints.up("md")]: {
    boxShadow: "none",
  },
}));

const MenuIconWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  top: "20px",
  right: "20px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const MenuItems = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const IconWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) &&
    prop !== "width" &&
    prop !== "height" &&
    prop !== "logout" &&
    prop !== "flip" &&
    prop !== "active",
})(({ theme, width, height, logout, active, flip }) => ({
  width: "20px",
  height: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& svg": {
    height: `${width}px`,
    width: `${height}px`,
    marginRight: "10px",
    transform: flip
      ? theme.direction == "rtl"
        ? "scaleX(1)"
        : "scaleX(-1)"
      : "scaleX(1)",
  },
}));

const MenuItemIcon = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  width: "20px",
  height: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const CloseButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  top: "10px",
  right: "10px",
  display: "block",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const MenuItem = styled("div", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) &&
    prop !== "active" &&
    prop !== "logout" &&
    prop !== "flip",
})(({ theme, active, logout, flip }) => ({
  display: "flex",
  alignItems: "center",
  padding: "11px 22px",
  marginBottom: "3px",
  cursor: "pointer",
  transition: "all 0.2s ease",
  backgroundColor: active ? "rgba(0, 190, 173, 0.08)" : "#fff",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "rgba(0, 190, 173, 0.08)",
  },
  "& p": {
    transition: "all 0.2s ease",
    // color: active ? theme.palette.primary.main : theme.palette.brownGray.main,
  },
  "&:hover p": {
    color: theme.palette.primary.main,
  },
  "&:hover svg": {
    color: logout ? theme.palette.red.main : theme.palette.primary.main,
  },
  "& svg": {
    color: logout
      ? theme.palette.red.main
      : active
      ? theme.palette.primary.main
      : theme.palette.brownGray.main,
  },
}));

export {
  SidebarLayoutStyled,
  LeftBlock,
  RightBlock,
  ProfileHeaderBlock,
  UserInfoBlock,
  UserInfoBlockBottom,
  UserInfoImageWrapper,
  SidebarMenuBlock,
  MenuItem,
  MenuItems,
  MenuItemIcon,
  MenuFoldIcon,
  MenuUnfoldIcon,
  MenuIconWrapper,
  IconWrapper,
  CloseButtonWrapper,
};
