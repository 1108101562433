import React from "react";
import Image from "next/image";
import { Avatar, UserStyled } from "./style";
import AvatarImage from "../../../../../../public/images/default-avatar.jpg";
import { useTranslation } from "next-i18next";
import Link from "@mui/material/Link";
import { StyledLink } from "../../../../common/Link/CustomLink";
import CustomLink from "../../../../common/Link/CustomLink";
import { useAuth } from "../../../../../context/auth";

const User = () => {
  const { user, logout } = useAuth();
  const { t } = useTranslation("common");
  const handleLogout = () => {
    logout();
  };

  return (
    <UserStyled>
      <Link
        component={StyledLink}
        href={!user ? "/login" : "/profile"}
        className="textdecorationnone"
      >
        {user ? (
          <span>
            {user?.firstName
              ? user.firstName
              : user.email
              ? user?.email
              : t("welcome")}
          </span>
        ) : (
          t("login_signup")
        )}
      </Link>

      {/* {user && (
        <Link
          component={StyledLink}
          href="#"
          underline="none"
          color="primary"
          variant="link"
          size="default"
        >
          <a
            onClick={() => {
              handleLogout();
            }}
          >
            {t("sign_out")}
          </a>
        </Link>
      )} */}
      <Link component={CustomLink} href={user ? "/profile" : "/login"}>
        <Avatar>
          <Image
            src={user?.avatarSourceUrl ? user?.avatarSourceUrl : AvatarImage}
            alt={t("default_avatar_image")}
            fill
            priority={true}
            style={{ objectFit: "cover", objectPosition: "center" }}
          />
        </Avatar>
      </Link>
    </UserStyled>
  );
};

export default User;
