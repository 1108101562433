import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { IoIosNotificationsOutline } from "react-icons/io";
import { AiFillNotification, AiFillCloseSquare } from "react-icons/ai";
import toRem from "../../../../../utils/toRem";
import { Badge } from "@mui/material";

const NotificationsStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  cursor: "pointer",
}));

const NotificationsPopupStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const NotificationIcon = styled(IoIosNotificationsOutline, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: toRem(28),
  color: theme.palette.primary.main,
}));

const NotAllowedIcon = styled(AiFillCloseSquare, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: toRem(12),
  color: theme.palette.red.main,
  position: "absolute",
  zIndex: 2,
  bottom: "1px",
  right: "-1px",
}));

const NotificationIconFill = styled(AiFillNotification, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: toRem(22),
  color: theme.palette.primary.main,
}));
// const NotificationIcon = styled(AiOutlineNotification, {
//   shouldForwardProp: (prop) => isPropValid(prop),
// })(({ theme }) => ({
//   color: theme.palette.primary.main,
//   fontSize: "22px",
// }));

const BadgeStyled = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 7,
    top: 6,
    border: `2px solid ${theme.palette.white.main}`,
    padding: `3px 5px`,
    borderRadius: `10px`,
    cursor: "pointer",
    color: "white",
  },
}));

export {
  NotificationsStyled,
  NotificationIcon,
  BadgeStyled,
  NotificationsPopupStyled,
  NotificationIconFill,
  NotAllowedIcon,
};
