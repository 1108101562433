import { useQuery } from "react-query";
import { useAxios } from "./axios";

//fetch all sellers
const useGetSellers = (filter, enabled = true) => {
  const axios = useAxios();
  return useQuery(
    ["sellers"],
    async () => {
      const { data } = await axios.get("profiles/v1/sellers", {
        northWestCoordinate: {
          Latitude: 0,
          Longitude: 0,
        },
        southEastCoordinate: {
          Latitude: 0,
          Longitude: 0,
        },
        page: filter?.filter?.page,
        pageSize: filter?.filter?.followPerPage,
      });
      return data;
    },
    { enabled: enabled }
  );
};
export default useGetSellers;
