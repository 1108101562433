import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import {
  PlusIcon,
  MessagesIcon,
  MapPinIcon,
  HomeIcon,
  ProfileIcon,
} from "./icons";
import { green } from "@mui/material/colors";

const FooterMenuStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "visible",
})(({ theme, visible }) => ({
  position: "fixed",
  bottom: "-10px",
  left: 0,
  right: 0,
  // padding: "0 0 15px 0",
  backgroundColor: "#fff",
  zIndex: 99,
  display: visible ? "flex" : "none",
  justifyContent: "center",
  borderTop: "1px solid #F1F1F1",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const CustomHomeIcon = styled(HomeIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: "#575756",
}));

const CustomPlusIcon = styled(PlusIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: "#575756",
}));

const CustomMessagesIcon = styled(MessagesIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: "#575756",
}));

const CustomMapPinIcon = styled(MapPinIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: "#575756",
}));

const CustomProfileIcon = styled(ProfileIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: "#575756",
}));

const FooterButton = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "active",
})(({ theme, active }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  transform: active ? "translateY(-28px)" : "",
  minWidth: "70px",
}));

const IconWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) &&
    prop !== "iconW" &&
    prop !== "iconH" &&
    prop !== "active",
})(({ theme, iconW = 24, iconH = 24, active }) => ({
  cursor: "pointer",
  width: "57px",
  height: "57px",
  backgroundImage: active
    ? `linear-gradient(89.14deg, #0055B8 -80.23%, #00C4B3 137.6%);`
    : "#fff",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& svg": {
    height: `${iconH}px`,
    width: `${iconW}px`,
    color: active ? "#fff" : "#575756",
  },
}));

export {
  FooterMenuStyled,
  CustomPlusIcon,
  CustomMessagesIcon,
  CustomMapPinIcon,
  CustomHomeIcon,
  CustomProfileIcon,
  FooterButton,
  IconWrapper,
};
