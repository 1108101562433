import React, { useEffect } from "react";
import {
  BadgeStyled,
  NotAllowedIcon,
  NotificationIcon,
  NotificationIconFill,
  NotificationsPopupStyled,
  NotificationsStyled,
} from "./style";
import { fetchToken, onMessageListener, onNafathSocketResponse } from "../../../../../utils/firebase";
import { toast } from "react-toastify";
import { useAxios } from "../../../../../hooks/axios";
import { useAuth } from "../../../../../context/auth";
import useGetUnReadNotificationCount from "../../../../NotificationsAndLogs/NotificationsList/hooks/useGetUnReadNotificationCount";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";
import { useQueryClient } from "react-query";
import { toastOptions } from "../../../../../utils/utils";
import { useTranslation } from "next-i18next";
import Cookies from "js-cookie";
import { useState } from "react";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { CustomButton } from "../../../Buttons/Buttons";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import AccountVerifiedOk from "../../../../AccountVerifiedOk/AccountVerifiedOk";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CloseIcon from "@mui/icons-material/Close";
import { onValue, realtimedb, ref, remove } from "../../../../../utils/firebase";
import { isAndroid, isBrowser } from "react-device-detect";
import { event } from "../../../../../utils/gtm";

const Notifications = () => {
  const axios = useAxios();
  const { t } = useTranslation("common");
  const router = useRouter();

  const queryClient = useQueryClient();
  const tokenInLocalForage = Cookies.get("sanadak_fcm_token");
  const profileId = Cookies.get("profile_id");
  const forNotification = Cookies.get("for_notification");
  const [open, setOpen] = useState(false);
  const [nafathLoginOpen, setNafathLoginOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tokenModalOpen, setTokenModalOpen] = useState(false);

  const notifiRef = useRef(null);
  const {
    setPushToken,
    pushToken,
    user,
    setNotificationCount,
    notificationCount,
    setTimer,
    setLoginRefresh,
    nafathTimer,
    setNafathTimer,
    setProfileNafath,
    profileNafath
  } = useAuth();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if(profileNafath != null) {
      const intervalId = setInterval(() => {
        if(nafathTimer == 0) {
          setProfileNafath(null);
          setNafathTimer(null);
          clearInterval(intervalId);
        }else {
          setNafathTimer(nafathTimer - 1);
          handleRefreshToken();
        }
      }, 10000);

      return () => clearInterval(intervalId);

      // onValue(ref(realtimedb, `/${process.env.NEXT_PUBLIC_FB_REF}/` + profileId), (snapshot) => {
      //   console.log("snapshot.val() => ", );
      //   // if(snapshot.val() !== null) {
      //   //   handleRefreshToken();
      //   // }
      // });
    }
  }, [profileNafath, nafathTimer]);

  useEffect(() => {
    if (tokenInLocalForage) {
      setPushToken(tokenInLocalForage);
    }else {
      if((isAndroid || isBrowser) && profileId && forNotification == undefined) {
        setTokenModalOpen(false);
        setOpen(true);
      }
    }
  }, [tokenInLocalForage]);

  const { data: count, isLoading } = useGetUnReadNotificationCount({
    pushToken,
    enabled: pushToken,
  });

  useEffect(() => {
    if (count) {
      setNotificationCount(count);
    }
  }, [count]);
  const handlePopupClose = () => {
    setTokenModalOpen(false);
    setOpen(false);
  };
  const handleRefreshToken = () => {
    axios
      .post("/identity/v1/logins/refresh")
      .then((data) => {
        if (Object.keys(data.data).length != 0) {
          Cookies.set("access_token", data?.data?.data?.access_token); // {   expires: data?.expires_in, }
          Cookies.set("id_token", data?.data?.data?.id_token);
          window.localStorage.setItem('access_token', data?.data?.data?.access_token);
          window.localStorage.setItem('id_token', data?.data?.data?.id_token);

          // Cookies.remove("provided_id");
          toast.success(
            t("you_are_successfully_created_profile"),
            toastOptions
          );
          
          setProfileNafath(null);
          setNafathTimer(null);
          setLoginRefresh(true);

          event({
            action: "registeration_completed",
            params: {}
          });

          router?.push({
            pathname: "/",
          });

          // 
          // Remove user ref after nafath
          //
          //remove(ref(realtimedb, `/${process.env.NEXT_PUBLIC_FB_REF}/` + user?.id));
        }
      })
      .catch((err) => {});
  };

  const handleToken = (event) => {
    if (pushToken) {
      if (user) {
        router?.push({
          pathname: "/notifications",
        });
      } else {
        router?.push({
          pathname: "/notifications-listing",
        });
      }
    } else {
      fetchToken().then((token) => {
        if (token && token != "not-grant") {
          setOpen(false);
          // setAnchorEl(null);
          setPushToken(token);
          registerPushToken(token);
          if (user) {
            router?.push({
              pathname: "/notifications",
            });
          } else {
            router?.push({
              pathname: "/notifications-listing",
            });
          }
        } else {
          setTokenModalOpen(true);
          setAnchorEl(anchorEl ? null : event.currentTarget);
          // setAnchorEl(true);
        }
      });

      // setTokenFound(true);
      // const { data } = await axios.post(`/messages/v1/push`, {
      //   ProviderToken: token,
      //   NativeToken: "",
      //   ExpiredToken: "",
      // });
    }
  };

  // useEffect(() => {
  //   if (pushToken) {
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pushToken]);

  const registerPushToken = async (pushToken) => {
    await axios.post(`/messages/v1/push`, {
      ProviderToken: pushToken,
      NativeToken: "",
      ExpiredToken: "",
    });
  };

  return (
    <>
      <NotificationsStyled ref={notifiRef}>
        {/* {!pushToken && <NotAllowedIcon onClick={handleClick} />} */}

        <BadgeStyled color="red" badgeContent={!pushToken ? 1 : count?.data}>
          {/* <NotificationIcon onClick={pushToken ? handleToken : handleClick} /> */}
          <NotificationsNoneIcon
            sx={{ color: "primary.main", fontSize: "30px" }}
            onClick={() => {
              if(!user) {
                router.push("/login");
              }else {
                if(pushToken) {
                  handleToken();
                }else {
                  setOpen(true);
                }
              }
            }}
          />
        </BadgeStyled>
      </NotificationsStyled>

      {/* <Popper id={id} open={true} anchorEl={anchorEl} transition> */}
      {!tokenModalOpen ? (
        <Popper
          id="simple-popper"
          open={open}
          anchorEl={notifiRef.current}
          transition
          sx={{ zIndex: 99 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Paper sx={{ p: "15px", m: "30px" }}>
                <Typography variant="body2" sx={{ mb: "10px" }}>
                  {t("notifications")}
                </Typography>
                <Typography
                  variant="body3"
                  size="smaller"
                  sx={{ mb: "10px", lineHeight: 1.5 }}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "please_enable_permission_to_receive_our_notifications"
                    ),
                  }}
                />
                <Typography
                  variant="body3"
                  size="smaller"
                  sx={{ mb: "8px" }}
                ></Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: "-5px",
                  }}
                >
                  <Stack direction={"row"} spacing={2}>
                    <CustomButton variant="text" onClick={handleToken}>
                      {t("accept")}
                    </CustomButton>
                    <CustomButton
                      variant="text"
                      color="red"
                      onClick={handleClose}
                    >
                      {t("cancel")}
                    </CustomButton>
                  </Stack>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      ) : (
        <Popper
          id="simple-popper"
          open={tokenModalOpen}
          anchorEl={notifiRef.current}
          transition
          sx={{ zIndex: 99 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Paper sx={{ p: "15px" }}>
                <Typography variant="body2" sx={{ mb: "10px" }}>
                  {t("notifications")}
                </Typography>
                <Typography
                  variant="body3"
                  size="smaller"
                  sx={{ mb: "10px", lineHeight: 1.5 }}
                  dangerouslySetInnerHTML={{
                    __html: t("inorder_complete_signup_process"),
                  }}
                />
                <Typography
                  variant="body3"
                  size="smaller"
                  sx={{ mb: "8px" }}
                ></Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: "-5px",
                  }}
                >
                  <Stack direction={"row"} spacing={2}>
                    <CustomButton
                      variant="text"
                      color="red"
                      onClick={handlePopupClose}
                    >
                      {t("cancel")}
                    </CustomButton>
                  </Stack>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
};

export default Notifications;

// onMessageListener()
//     .then((payload) => {
//       if (payload?.data?.pushType != "nafathStatusUpdate") {
//         queryClient.invalidateQueries(["notification", "list"]);
//         queryClient.invalidateQueries(["notifications", "unread-count"]);
//         toast.success(
//           <NotificationsPopupStyled>
//             <Typography variant="body2" sx={{ mb: "4px" }}>
//               {payload?.notification?.title}
//             </Typography>
//             <Typography variant="body3" size="smaller">
//               {payload?.notification?.body}
//             </Typography>
//           </NotificationsPopupStyled>,
//           {
//             closeOnClick: false,
//             autoClose: false,
//             icon: ({ theme, type }) => <NotificationIconFill />,
//           },
//           toastOptions
//         );
//       } else {
//         if (payload?.data?.verification == "rejected") {
//           toast.error(t("verification_rejected"));
//           setTimer(0);
//         } else {
//           handleRefreshToken();
//         }
//       }
//     })
//     .catch((err) => console.log("failed: ", err));