import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import toRem from "../../../utils/toRem";
import { Container } from "@mui/material";

const FooterStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "morePadding",
})(({ theme, morePadding }) => ({
  position: "relative" ,
  // zIndex: 3,
  backgroundImage: `linear-gradient(89.14deg, #0055B8 -80.23%, #00C4B3 137.6%);`,
  padding: morePadding ? "30px 15px 140px 15px" : "30px 15px 20px 15px",
  [theme.breakpoints.up("sm")]: {
    padding: morePadding ? "55px 45px 140px 45px" : "55px 45px 20px 45px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "55px 45px 20px 45px",
  },
}));

const FooterContent = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const FooterInfoBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const Logo = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  height: "60px",
  width: "130px",
}));

const FooterInfoBlockHeader = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  // minHeight: "36px",
  marginBottom: toRem(10),
  "& h3": {
    color: theme.palette.white.main,
    fontSize: toRem(16),
    margin: 0,
    fontWeight: "normal",
  },
}));

const FooterInfoBlockContent = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  "& ul": { margin: 0, padding: 0 },
  "& li": {
    color: theme.palette.white.main,
    fontSize: toRem(12),
    listStyleType: "none",
    marginBottom: toRem(5),
  },
}));

const Copyright = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  marginTop: "40px",
  "& p": {
    color: theme.palette.white.main,
    fontSize: toRem(16),
    textAlign: "center",
    fontFamily: "Avenir-Regular",
  },
}));

const ContainerStyled = styled(Container, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const SocialMedias = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  margin: "0 -10px",
}));

const SocialMedia = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "inline-block",
  position: "relative",
  width: "25px",
  height: "25px",
  margin: " 0 10px",
  overflow: "hidden",
}));

const LogoBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "110px",
  [theme.breakpoints.up("sm")]: {
    minHeight: "120px",
  },
}));

const LinksBlocks = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  margin: "40px 0",
  [theme.breakpoints.up("md")]: {
    margin: 0,
    margin: "0 60px",
  },
  [theme.breakpoints.up("lg")]: {
    margin: 0,
    margin: "0 100px",
  },
  [theme.breakpoints.up("xl")]: {
    margin: 0,
    margin: "0 200px",
  },
}));

const LinksBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const AppStoresBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

export {
  FooterStyled,
  Copyright,
  FooterContent,
  FooterInfoBlock,
  FooterInfoBlockHeader,
  FooterInfoBlockContent,
  Logo,
  ContainerStyled,
  SocialMedias,
  SocialMedia,
  LogoBlock,
  LinksBlocks,
  LinksBlock,
  AppStoresBlock,
};
