import { useQuery } from "react-query";
import { useAxiosSearch } from "./axiosSearch";

//fetch all sellers
const useSellerSearch = (filter, enabled = true) => {
  const axios = useAxiosSearch();

  return useQuery(
    ["sellerSearch"],
    async () => {
      const { data } = await axios.post("search/v1/profile/sellers", {
        showOwn : false,
        agentsWithAd : true,
        page: filter?.filter?.page,
        pageSize: filter?.filter?.pageSize,
        query: filter?.filter?.agentSearch
      });
      return data;
    },
    { enabled: enabled }
  );
};
export default useSellerSearch;
