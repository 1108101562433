import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { IoMdSettings } from "react-icons/io";

const FilterIcon = styled(IoMdSettings, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: "18px",
  marginRight: "5px",
}));

export { FilterIcon };
