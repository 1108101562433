import React, { useEffect, useState } from "react";

import Image from "next/image";
import {
  CustomSearchWithFilterButtonStyled,
  FilterAnchor,
  FilterButtonWrapper,
  FilterContainer,
  SearchAreaWrapper,
  SearchButtonWrapper,
  SearchFieldWrapper,
} from "./style";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "next-i18next";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../utils/theme";
import { useSearchDebounce } from "../../utils/utils";
import { useRouter } from "next/router";
import useGetAllSearchResult from "../../hooks/useGetAllSearchResult";
import { useHeaderSearch } from "../../hooks/useSearchFilters";
import _ from "lodash";
import Cookies from "js-cookie";
import { event } from "../../utils/gtm";
import { combineStrings } from "../../utils/combineStrings";

const CustomSearchWithFilterButton = ({
  isSearchExpand,
  setIsSearchExpand,
  menuVisible,
  setMenuVisible,
}) => {
  const [selected, setSelected] = useState(null);
  const [recentValue, setRecentValue] = useState([]);
  const [inputValue, setInputValue] = useSearchDebounce();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchResultList, setSearchResultList] = useState(null);
  const router = useRouter();
  const [enabled, setEnabled] = useState(false);
  const { t } = useTranslation("common");
  const page = 100;

  const filters = {
    pageSize: page,
    query: inputValue,
  };

  const { data, isLoading, refetch } = useHeaderSearch({
    filters,
    enabled: enabled,
    setSearchResultList,
  });
  // useEffect(() => {
  //   if (recentValue) {
  //     Cookies.set("recent_search", recentValue);
  //   }
  // }, [recentValue]);

  // const recentSearch = Cookies.get("mycookie");
  // let array = JSON?.parse(recentSearch);
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  let recentSearch = "";
  useEffect(() => {
    if (inputValue) {
      let searchArray = [];
      //  specialChars.test(str);

      recentSearch = Cookies.get("recent_search");
      if (recentSearch) {
        searchArray = JSON.parse(recentSearch);
      }

      if (
        !searchArray.includes(inputValue) &&
        inputValue?.length >= 3 &&
        !specialChars.test(inputValue)
      ) {
        searchArray.unshift(inputValue);
      }
      if (data != undefined && data.length > 0) {
        Cookies.set("recent_search", JSON.stringify(searchArray));
      }
      refetch();
    } else {
      setSearchResultList(null);
    }
  }, [inputValue]);
  let searchData = [];

  const searchResult = _.filter(searchResultList, (option) => {
    if (
      option.entityType == "profile" ||
      option.entityType == "project" ||
      option.entityType == "listing" ||
      option.entityType == "place"
      // option.entityType == "Recent search"
    ) {
      return option;
    }
  });
  if (searchResult) {
    let searchValue = Cookies.get("recent_search");
    if (searchValue) {
      let searchArrays = JSON.parse(searchValue);
      searchData = _.map(searchArrays, (item) => {
        return { entityType: "Recent search", title: item, id: item };
      });
    }
  }

  // const searchResult = _.map(recentSearch, (item) => item);
  const searchDataList = searchData.slice(0, 4);

  const handleSearch = () => {
    if (matches) {
      refetch();
    } else {
      setIsSearchExpand(true);
      if (isSearchExpand) {
        refetch();
      }
    }
  };

  return (
    <CustomSearchWithFilterButtonStyled isSearchExpand={isSearchExpand}>
      {isSearchExpand && (
        <IconButton
          sx={{
            display: ["block", "block", "none"],
            p: 0,
          }}
          onClick={() => setIsSearchExpand(false)}
        >
          {router?.locale == "ar" ? (
            <ChevronLeftIcon
              sx={{
                color: "primary.main",
                mt: "5px",
                ml: "-15px",
                fontSize: "30px",
              }}
            />
          ) : (
            <ChevronRightIcon
              sx={{
                color: "primary.main",
                mt: "5px",
                ml: "-15px",
                fontSize: "30px",
              }}
            />
          )}
        </IconButton>
      )}
      <SearchAreaWrapper isSearchExpand={isSearchExpand}>
        <SearchFieldWrapper isSearchExpand={isSearchExpand}>
          <Autocomplete
            id="search"
            autoHighlight
            options={searchResult?.length < 1 ? searchDataList : searchResult}
            filterOptions={(x) => x}
            groupBy={(option) => t(option?.gTitle)}
            getOptionLabel={(option) => {
              return option?.title;
            }}
            sx={{ width: 300 }}
            value={selected}
            onChange={(event, newValue, reason) => {
              if (reason == "selectOption") {
                let navigateUrl = false;
                if (newValue?.title == t("show_more")) {
                  Cookies.set("more-results", JSON.stringify({
                    query: inputValue,
                    entity: newValue?.entityType
                  }));
                  if (router?.locale == "en") {
                    window.location.href = `/en/more-results`;
                  } else {
                    window.location.href = `/more-results`;
                  }
                } else if (newValue?.entityType == "project") {
                  // navigateUrl = router?.locale == "ar" ? `/project/${newValue?.title?.replace(/ /g, "-").replace(/,/g, "")}` : `/en/project/${newValue?.title?.toLocaleLowerCase().replace(/ /g, "-").replace(/,/g, "")}`;
                  navigateUrl =router.locale =="ar" ?  `/project/${newValue?.id}` : `/en/project/${newValue?.id}`;

                } else if (newValue?.entityType == "listing") {
                  const propertySlug = newValue.title.replace(/,?\s+/g, '-').toLowerCase();
                  //navigateUrl = `/property/${newValue?.id}`;
                  navigateUrl = `/${router.locale}/property-details/${propertySlug}-${newValue.advertisementNumber}`;
                } else if (newValue?.entityType == "profile") {
                  navigateUrl = router?.locale == "ar" ? `/agent/${newValue?.userName}` : `/en/agent/${newValue?.userName}`;
                } else if (newValue?.entityType == "place") {
                  // navigateUrl = router?.locale == "ar" ? `/areas/${newValue?.title?.replace(/ /g, "-").replace(/,/g, "")}` : `/en/areas/${newValue?.title?.toLocaleLowerCase().replace(/ /g, "-").replace(/,/g, "")}`;
                  navigateUrl = router?.locale == "ar" ? `/areas/${newValue?.id}` : `/en/areas/${newValue?.id}`;
                }
                if (navigateUrl) {
                  // router.push({ pathname: navigateUrl });
                  window.location.href = navigateUrl;
                }
              } else {
                setSelected(newValue);
              }
            }}
            loading={isLoading}
            onInputChange={(e, newInputValue) => {
              if (newInputValue?.length >= 3) {
                event({
                  action: "search_bar_text",
                  params: {}
                });

                setRecentValue(newInputValue);
                setInputValue(newInputValue);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder={t("search")} />
            )}
            renderOption={(props, option) => {
              if (option.title == "Recent search") {
                return (
                  <li {...props} key={option.id}>
                    {option?.title}
                  </li>
                )
              }

              if (option.title ==t("show_more")) {
                return (
                  <li {...props} key={option.id} style={{ color: "#00bead", fontWeight: 'bold' }}>
                    {t("show_more")}
                  </li>
                )
              }

              return (
                <li {...props} key={option.id}>
                  {option.title}
                </li>
              )
            }}
            noOptionsText={recentSearch ? recentSearch : t("no_options")}
          />
        </SearchFieldWrapper>
        <SearchButtonWrapper>
          <IconButton sx={{ p: "4px" }} onClick={handleSearch}>
            <SearchIcon sx={{ color: "primary.main", fontSize: "30px" }} />
          </IconButton>
        </SearchButtonWrapper>
      </SearchAreaWrapper>
      <FilterButtonWrapper isSearchExpand={isSearchExpand}>
        <button onClick={() => setMenuVisible(!menuVisible)}>
          <Image
            width={13}
            height={13}
            src={require("./icons/filter-icon.svg")}
            alt="Filter icon"
          />
        </button>
      </FilterButtonWrapper>
    </CustomSearchWithFilterButtonStyled>
  );
};

export default CustomSearchWithFilterButton;
