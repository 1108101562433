import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const ViewSwitcherStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const SwitcherTabs = styled("ul", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  margin: 0,
  padding: 0,
  border: "1px solid #f2f2f2",
  borderRadius: "15px",
  display: "flex",
}));

const SwitcherTab = styled("li", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "active",
})(({ theme, active }) => ({
  listStyleType: "none",
  padding: "5px 25px",
  margin: "5px",
  borderRadius: "15px",
  transition: "background 0.2s ease",
  cursor: "pointer",
  backgroundColor: active ? theme.palette.primary.main : "#ffff",
  "& P": {
    color: active ? "#ffff" : theme.palette.primary.main,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "&:hover p": {
    color: "#fff",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "5px 75px",
  },
}));

export { ViewSwitcherStyled, SwitcherTabs, SwitcherTab };
