import React from "react";
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";
import {
  ContainerStyled,
  FooterWrapper,
  MainStyled,
} from "../MainLayout/style";
import { SignUpLayoutStyled } from "./style";
import { useState } from "react";

const SignUpLayout = ({ children }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <ContainerStyled maxWidth="xl">
      <Header menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
      <MainStyled>
        <SignUpLayoutStyled>{children}</SignUpLayoutStyled>
      </MainStyled>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </ContainerStyled>
  );
};

export default SignUpLayout;
