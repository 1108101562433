import AuthenticationLayout from "../layouts/AuthenticationLayout/AuthenticationLayout";
import MainLayout from "../layouts/MainLayout/MainLayout";
import PlainLayout from "../layouts/PlainLayout/PlainLayout";
import SidebarLayout from "../layouts/Sidebarlayout/SidebarLayout";
import SignUpLayout from "../layouts/SignUpLayout/SignUpLayout";

export default function withLayout(layoutType, title = false) {

  if (layoutType === "auth") {
    return function getLayout(page) {
      return <AuthenticationLayout title={title}>{page}</AuthenticationLayout>;
    };
  }
  if (layoutType === "sidebar") {
    return function getLayout(page) {
      return <SidebarLayout title={title}>{page}</SidebarLayout>;
    };
  }
  if (layoutType === "sign-up") {
    return function getLayout(page) {
      return <SignUpLayout>{page}</SignUpLayout>;
    };
  }
  if (layoutType === "plain") {
    return function getLayout(page) {
      return <PlainLayout>{page}</PlainLayout>;
    };
  }

  return function getLayout(page) {
    return <MainLayout>{page}</MainLayout>;
  };
}
