import { useRouter } from "next/router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAxios } from "../../../hooks/axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
/**
 *
 * @param {filters}
 * @returns the list of following
 */
const useGetFollowing = (filters, enabled = false) => {
  const axios = useAxios();
  let qKey = ["following", "result", { ...filters }];
  if (filters?.profile == "own") {
    qKey = ["following", "own"];
  }
  return useQuery(
    qKey,
    async () => {
      const { data } = await axios.get("/follow/v1/following", {
        params: {
          offset: filters?.offset ?? "",
          limit: filters?.limit ?? "",
          userId: filters?.userId ?? "",
        },
      });
      return data;
    },
    { enabled: enabled }
  );
};

/**
 *
 * @param {filters}
 * @returns the list of followers
 */
const useGetFollowers = (filters, enabled = true) => {
  const axios = useAxios();
  return useQuery(
    ["followers", "result", { ...filters }],
    async () => {
      if (filters?.userId) {
        const { data } = await axios.get("/follow/v1/followers", {
          params: {
            offset: filters?.offset ?? "",
            limit: filters?.limit ?? "",
            userId: filters?.userId ?? "",
          },

        });

        return data;
      }
    },
    { enabled: enabled }
  );
};

const useGetFollowingPropertyData = ({ filters }, enabled = false) => {
  const axios = useAxios();
  const router = useRouter();
  let fQry = ["following"];
  if (filters?.profile) {
    fQry = ["following", "seller"];
  }
  return useQuery(
    fQry,
    async () => {
      const { data } = await axios.post(
        "/search/v1/Listings/following",
        {
          page: filters?.page,
          pageSize: filters?.pageSize,
          listingType: filters?.listingType,
          propertyType: filters?.propertyType,
          orderBy: filters?.orderBy,
        },
        {
          headers: {
            "Accept-Language": router?.locale == "en" ? "en-US" : "ar-SA en-US",
          },
        }
      );
      return data;
    },

    { enabled: enabled }
  );
};

const useGetHomeData = (homeFilters) => {
  const axios = useAxios();
  const router = useRouter();
  const city_id = Cookies.get("city_id");

  return useQuery(
    ["home-all"],
    async () => {
      const { data } = await axios.post(
        "/search/v1/listings/mapfilters",
        {
          page: homeFilters?.homePage,
          pageSize: 12,
          listingTypeFilter:
            homeFilters?.listingType == "buy"
              ? "sale"
              : homeFilters?.listingType,
          propertyType:
            homeFilters?.propertyType == "null"
              ? null
              : homeFilters?.propertyType,
          orderBy: homeFilters?.orderBy,
          defaultCity: city_id,
        },
        {
          headers: {
            "Accept-Language": router?.locale == "en" ? "en-US" : "ar-SA en-US",
          },
        }
      );
      return data;
    }
    // {
    //   staleTime: 500,
    // }
  );
};

const useMakeFollowUser = (id) => {
  const axios = useAxios();
  const router = useRouter()
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      const { data } = await axios.put(`/follow/v1/${values}/followuser`);
      return data;
    },
    {
      onSuccess: (data, values) => {
        let profile = queryClient.getQueryData(["profile", values]);
        if (profile) {
          profile.data.isFollowing = !profile.data.isFollowing;
          profile.data.numberFollowers = profile.data.numberFollowers + 1;
          queryClient.setQueryData(["profile", values], profile);
        }
        let detail = queryClient.getQueryData(["property", "detail", id]);
        if (detail) {
          detail.data.isFollowingSeller = !detail.data.isFollowingSeller;
          queryClient.setQueryData(["property", "detail", id], detail);
        }

        let pDetail = queryClient.getQueryData([
          "project",
          "model",
          "detail",
          id,
        ]);
        if (pDetail) {
          pDetail.data.isFollowingSeller = !pDetail.data.isFollowingSeller;
          queryClient.setQueryData(["project", "model", "detail", id], pDetail);
        }

           if (router && router.query && router.query.slug) {
          const arr = router.query.slug.split('-');
          if (arr && arr.length > 0) {
            const lastItem = arr[arr.length - 1];
            if (lastItem) {
              let singleProperty = queryClient.getQueryData(["singleProperty", "detail", lastItem]);
              if (singleProperty) {
                singleProperty.data.isFollowingSeller = !singleProperty.data.isFollowingSeller;
                queryClient.setQueryData(["singleProperty", "detail", lastItem], singleProperty);
              }
            }
          }
        }
        
        // let agentDetail = queryClient.getQueryData(["profile", values]);
        // if (agentDetail) {
        //   agentDetail.data.isFollowing = !agentDetail.data.isFollowing;
        // }
        // queryClient.invalidateQueries(["property", "detail", id]);
        // queryClient.invalidateQueries(["following", "seller"]);
        queryClient.invalidateQueries(["project", "listing"]);
        queryClient.invalidateQueries(["area", "listing"]);
        queryClient.invalidateQueries(["agent", "listing"]);
        queryClient.refetchQueries(["following", "own"]);
        queryClient.invalidateQueries(["followers", "result"]);
        queryClient.invalidateQueries(["sellers"]);
        queryClient.invalidateQueries(["following", "result"]);

        let buyerProfile = queryClient.getQueryData(["buyer", "profile"]);

        if (buyerProfile) {
          buyerProfile.numberFollowings = buyerProfile.numberFollowings + 1;
          queryClient.setQueryData(["buyer", "profile"], buyerProfile);
        }
        // queryClient.removeQueries(["following", "seller"]);

     
      },
      onError: (data, error) => {
        // Handle the error here...
        toast.error(data?.response?.data?.error?.message);
      }
    }
  );
};

const useMakeUnFollowUser = (id) => {
  const router = useRouter()
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      const { data } = await axios.put(`/follow/v1/${values}/unfollowuser`);
      return data;
    },
    {
      onSuccess: (data, values) => {
        let profile = queryClient.getQueryData(["profile", values]);
        if (profile) {
          profile.data.isFollowing = !profile.data.isFollowing;
          profile.data.numberFollowers = profile.data.numberFollowers - 1;
          queryClient.setQueryData(["profile", values], profile);
        }

        let detail = queryClient.getQueryData(["property", "detail", id]);
        if (detail) {
          detail.data.isFollowingSeller = !detail.data.isFollowingSeller;
          queryClient.setQueryData(["property", "detail", id], detail);
        }

        let pDetail = queryClient.getQueryData([
          "project",
          "model",
          "detail",
          id,
        ]);
        if (pDetail) {
          pDetail.data.isFollowingSeller = !pDetail.data.isFollowingSeller;
          queryClient.setQueryData(["project", "model", "detail", id], pDetail);
        }
        if (router && router.query && router.query.slug) {
          const arr = router.query.slug.split('-');
          if (arr && arr.length > 0) {
            const lastItem = arr[arr.length - 1];
            if (lastItem) {
              let singleProperty = queryClient.getQueryData(["singleProperty", "detail", lastItem]);
              if (singleProperty) {
                singleProperty.data.isFollowingSeller = !singleProperty.data.isFollowingSeller;
                queryClient.setQueryData(["singleProperty", "detail", lastItem], singleProperty);
              }
            }
          }
        }

        queryClient.refetchQueries(["following", "own"]);
        queryClient.invalidateQueries(["following", "seller"]);

        queryClient.invalidateQueries(["followers", "result"]);
        queryClient.invalidateQueries(["following", "result"]);
        queryClient.invalidateQueries(["sellers"]);
        let buyerProfile = queryClient.getQueryData(["buyer", "profile"]);
        if (buyerProfile) {
          buyerProfile.numberFollowings = buyerProfile.numberFollowings - 1;
          queryClient.setQueryData(["buyer", "profile"], buyerProfile);
        }
        queryClient.removeQueries(["following", "seller"]);


      },
      onError: (data, error) => {
        // Handle the error here...
        toast.error(data?.response?.data?.error?.message);
      }
    }
  );
};

const useMakeFollowAreaAndProject = (filters) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      const { data } = await axios.put(
        `/follow/v1/${values?.id}/follow${values?.type}`
      );
      data["dtype"] = values?.type;
      return data;
    },
    {
      onSuccess: (data) => {
        if (filters?.id && filters?.type == "area") {
          let areaFollow = queryClient.getQueryData(["areas", filters?.id]);
          areaFollow.data.isFollowing = !areaFollow?.data?.isFollowing;
        }
        if (filters?.areaId && data?.dtype == "area") {
          let areaFollow = queryClient.getQueryData(["areas", filters?.areaId]);
          areaFollow.data.isFollowing = !areaFollow?.data?.isFollowing;
        }
        if (filters?.projectId && data?.dtype == "project") {
          let projectFollow = queryClient.getQueryData([
            "projects",
            filters?.projectId,
          ]);
          projectFollow.data.isFollowing = !projectFollow?.data?.isFollowing;
        }
        if (filters?.id && filters?.type == "project") {
          let projectFollow = queryClient.getQueryData([
            "projects",
            filters?.id,
          ]);
          projectFollow.data.isFollowing = !projectFollow?.data?.isFollowing;
          projectFollow.data.numberFollowers = projectFollow?.data?.numberFollowers + 1;

        }
        queryClient.invalidateQueries(["following", "own"]);
        queryClient.invalidateQueries(["following", "seller"]);
        queryClient.invalidateQueries(["following"]);

        queryClient.invalidateQueries(["following", "result"]);
        queryClient.invalidateQueries(["followers", "result"]);
        queryClient.invalidateQueries(["activity-log", "list"]);
        queryClient.invalidateQueries(["buyer", "profile"]);
        // queryClient.removeQueries(["following", "seller"]);
      },
    }
  );
};

const useMakeUnFollowAreaAndProject = (filters) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      const { data } = await axios.put(
        `/follow/v1/${values?.id}/unfollow${values?.type}`
      );
      data["dtype"] = values?.type;
      return data;
    },
    {
      onSuccess: (data) => {
        if (filters?.id && filters?.type == "area") {
          let areaFollow = queryClient.getQueryData(["areas", filters?.id]);
          areaFollow.data.isFollowing = !areaFollow?.data?.isFollowing;
        }
        if (filters?.areaId && data?.dtype == "area") {
          let areaFollow = queryClient.getQueryData(["areas", filters?.areaId]);
          areaFollow.data.isFollowing = !areaFollow?.data?.isFollowing;
        }
        if (filters?.projectId && data?.dtype == "project") {
          let projectFollow = queryClient.getQueryData([
            "projects",
            filters?.projectId,
          ]);
          projectFollow.data.isFollowing = !projectFollow?.data?.isFollowing;
        }
        if (filters?.id && filters?.type == "project") {
          let projectFollow = queryClient.getQueryData([
            "projects",
            filters?.id,
          ]);
          projectFollow.data.isFollowing = !projectFollow?.data?.isFollowing;
          projectFollow.data.numberFollowers = projectFollow?.data?.numberFollowers - 1;

        }
        queryClient.invalidateQueries(["following", "own"]);
        queryClient.invalidateQueries(["following", "seller"]);
        queryClient.invalidateQueries([["following"]]);

        queryClient.invalidateQueries(["following", "result"]);
        queryClient.invalidateQueries(["followers", "result"]);
        queryClient.invalidateQueries(["activity-log", "list"]);
        queryClient.invalidateQueries(["buyer", "profile"]);
        queryClient.removeQueries(["following", "seller"]);
      },
    }
  );
};

export {
  useGetFollowers,
  useGetFollowing,
  useGetFollowingPropertyData,
  useMakeFollowUser,
  useMakeUnFollowUser,
  useMakeFollowAreaAndProject,
  useMakeUnFollowAreaAndProject,
  useGetHomeData
};
